import React from 'react';

const INTP = () => {
    return (
        <div>
<h1>INTP</h1>

<p><strong>Career Path Insights</strong>
<ul><li>At work, you are motivated to solve complex problems in an original, innovative way. </li>
<li>You want to analyze systems and ideas thoroughly to create a deep understanding, and </li>
<li>Enjoy designing creative solutions to highly abstract problems.</li>
<li>You rarely have much interest in organizational traditions, preferring to forge your own path to innovation. </li>
<li>You hate being limited by bureaucracy and rules, and are often more in tune with the theoretical soundness of your ideas than you are with the practical applications. </li>
<li>You typically prefer to focus on creating the idea and leave the tedious details of implementation to someone else.</li>
<li>You work best independently or with a small team of colleagues that you perceive as smart, competent, and logical. </li>
<li>You quickly tire of colleagues who are aggressive or overbearing and </li>
<li>You can be dismissive of people who aren't as clever as yourself.</li>
<li>An ideal organization for you is flexible and non-traditional and </li>
<li>Values ingenuity over conformity. </li>
<li>An ideal job for you allows you to address complex theoretical or technical problems with creative, novel solutions.</li></ul>
</p>
<p><strong>Working in a Team</strong>
<ul><li>You are a smart, analytical team player who brings a strong understanding of complicated issues to the table. </li>
<li>You are usually most interested in the theoretical concerns that underpin the team's goal, and </li>
<li>You can assist the group in identifying fundamental principles and coming up with new ideas. </li>
<li>You have a proclivity for engaging with the team's vision, examining it sensibly and objectively, and proposing ideas and possibilities.</li>
<li>When you are given the flexibility to investigate logical difficulties in an original way as part of a team, you perform best. </li>
<li>You are good at coming up with novel ideas, </li>
<li>But you're not a big fan of the traditional method of doing things. </li>
<li>Members of your team who are adamant about sticking to tradition may clash with you. </li>
<li>You are the type of person who would rather argue a topic than make small talk.</li>
<li>If you spend too much time on niceties, you may grow irritable, and you may alienate team members who like a more personal touch.</li></ul>
</p>
<p><strong>Being a Leader</strong>
<ul><li>With your intelligence and unique ideas, you inspire people in leadership positions. </li>
<li>On the surface, you appear humble, but once you start talking about your ideas, you command everyone's attention. </li>
<li>You prefer to set the overarching aim and trust your team to address problems on their own, </li>
<li>Thus you give your reports a great deal of flexibility and freedom.</li>
<li>You enjoy discovering new possibilities and solving problems creatively, </li>
<li>But you may become so engrossed in the world of ideas that you forget to lead your teams into action. </li>
<li>You can have a hard time hammering out the details, so you delegate the task to someone else. </li>
<li>You excel at leading capable, intellectually motivated teams that grasp your complex ideas and can fill in the details to develop practical action plans.</li></ul>
</p>
</div>
  )
}
export default INTP
