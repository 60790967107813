import React, { useState } from 'react';
import ENFP from './ENFP';
import ENFJ from './ENFJ';
import ENTP from './ENTP';
import ENTJ from './ENTJ';
import ESFP from './ESFP';
import ESTP from './ESTP';
import ESTJ from './ESTJ';
import ESFJ from './ESFJ';
import INFP from './INFP';
import INFJ from './INFJ';
import INTP from './INTP';
import INTJ from './INTJ';
import ISFP from './ISFP';
import ISTP from './ISTP';
import ISTJ from './ISTJ';
import ISFJ from './ISFJ';
export function PersonalityRender (value) {
    switch(value) {
    case "ENFP": return (<ENFP/>)
    break;
    case "ESTJ" : return (<ESTJ/>)
    break;
    case "ISTJ" : return (<ISTJ/>)
    break;
    case "ENTJ" : return (<ENTJ/>)
    break;
    case "INTJ" : return (<INTJ/>)
    break;
    case "ESFJ" : return (<ESFJ/>)
    break;
    case "ISFJ" : return (<ISFJ/>)
    break;
    case "ENFJ" : return (<ENFJ/>)
    break;
    case "INFJ" : return (<INFJ/>)
    break;
    case "ESTP": return (<ESTP/>)
    break;
    case "ISTP": return (<ISTP/>)
    break;
    case "ENTP": return (<ENTP/>)
    break;
    case "INTP": return (<INTP/>)
    break;
    case "ESFP": return (<ESFP/>)
    break;
    case "ISFP" : return (<ISFP/>)
    break; 
    case "ENFP" : return (<ENFP/>)
    break;
    case "INFP" : return (<INFP/>)
    break;
    default:return (<ENFP/>)
    break;
    }
};

export function PersonalityQuiz (value) {
    switch(value) {
    case "ENFP": return 702
    break;
    case "ESTJ" : return 707
    break;
    case "ISTJ" : return 715
    break;
    case "ENTJ" : return 703
    break;
    case "INTJ" : return 711
    break;
    case "ESFJ" : return 705
    break;
    case "ISFJ" : return 713
    break;
    case "ENFJ" : return 701
    break;
    case "INFJ" : return 709
    break;
    case "ESTP": return 708
    break;
    case "ISTP": return 716
    break;
    case "ENTP": return 704
    break;
    case "INTP": return 712
    break;
    case "ESFP": return 706
    break;
    case "ISFP" : return 714
    break; 
    case "INFP" : return 710
    break;
    default:return 702
    break;
    }
};