// React packages and UI
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
// Global State
import { useQuizContext } from '../utils/GlobalState';
import { idbPromise } from '../utils/helpers';
import { USER_DATA, UPDATE_QUESTIONS } from '../utils/actions';
// Components
import ResultsRender from '../components/ResultsRender/ResultsRender';
import ResultsRenderA from '../components/ResultsRender/ResultsRenderA';
import ResultsRenderB from '../components/ResultsRender/ResultsRenderB';
import ResultsRenderB1 from '../components/ResultsRender/ResultsRenderB1';
import ResultsRenderC from '../components/ResultsRender/ResultsRenderC';
import ResultsRenderC1 from '../components/ResultsRender/ResultsRenderC1';
import ResultsRenderF from '../components/ResultsRender/ResultsRenderF';
import ResultsRenderG from '../components/ResultsRender/ResultsRenderG';
import ResultsRenderH from '../components/ResultsRender/ResultsRenderH';
import Auth from '../utils/auth';
// GraphQL API
import { GET_SELECTED_QUIZ, QUERY_ME } from '../utils/queries';
//import { ADD_USER_QUIZ } from '../utils/mutations';
import { useQuery, useMutation } from '@apollo/react-hooks';
// icons
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Result = () => {
    const { quizNum } = useParams();
    const [state, dispatch] = useQuizContext();
 
    const { loading: userLoading, data: userData } = useQuery(QUERY_ME);
    const { loading: questionLoading, data: questionData } = useQuery(GET_SELECTED_QUIZ, {
        variables: { quizNum: Number(quizNum) }
      });

    const quiz = questionData?.quiz || [];
    // console.log(quiz)
    const me = userData?.me || [];
    const answers = {...me.userResult};
    let quizId = quiz._id;
    // console.log(quiz.quizQuestion);
    console.log({answers});

    useEffect(() => {
        if (questionData) {
          dispatch({
            type: UPDATE_QUESTIONS,
            questions: quiz
          });
        quiz.quizQuestion.forEach(quiz => {
            idbPromise('questions', 'put', quiz);
          });
        } else if (!questionLoading) {
          idbPromise('questions','get').then(questions => {
            dispatch({
              type: UPDATE_QUESTIONS,
              questions: questions
            });
          });
        };
    
        if (userData) {
          dispatch({
            type: USER_DATA,
            userdata: userData.me
          });
        idbPromise('userdata','put', userData.me);
        } else if (!userLoading) {
          idbPromise('userdata','get').then(userdata => {
            dispatch({
              type: USER_DATA,
              userdata: userdata
            });
          });
        }
        console.log('update')
      }, [userData, userLoading, questionData, questionLoading, dispatch ]);

    const loggedIn = Auth.loggedIn();

    switch(quiz.quizCategory) {
      case "CategoryA":
        return (
          <div>
             {loggedIn ? (
            <main>
                <div>              
            {questionLoading ? (
                <Spinner className="spinner" animation="grow" />
            ) : (
                <>
                {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
                <h3 className="text-center">Your answers for {quiz.quizTitle}.</h3>
                {/* <h4 className="text-center">There were {quiz.quizQuestion.length} questions.</h4> */}
                </>
            )}
              </div>
            <div>
            {userData && me.userResult.length
            ? (
            <>
              <hr />
                <ResultsRenderA
                me={me}
                answers={me.userResult} 
                quizIdProp={quiz._id}
                quizObject={quiz}
                quizQuestion = {quiz.quizQuestion}
                />
            </>
            // peppercorn specific name change from Quizzes to Exercises and results to answers
              ) : (<h4 className="text-center">No answers yet.</h4>)} 
            </div>
            </main>
                ) : (
                  <>
                  {localStorage.setItem('referring_url', window.location.href)}
                  <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page.</h4> 
                  </>
            )}
              <hr />
              </div>
          )
      break;
      case "CategoryB":
        return (
          <div>
          {loggedIn ? (
         <main>
             <div>              
         {questionLoading ? (
             <Spinner className="spinner" animation="grow" />
         ) : (
             <>
             {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
             <h3 className="text-center">Your answers for {quiz.quizTitle}.</h3>
             </>
         )}
           </div>
         <div>
         {userData && me.userResult.length
         ? (
         <>
           <hr />
             <ResultsRenderB
             me={me}
             answers={me.userResult} 
             quizIdProp={quiz._id}
             quizObject={quiz}
             quizQuestion = {quiz.quizQuestion}
             />
         </>
         //peppercorn specific name change from Quizzes to Exercises and results to answers
           ) : (<h4 className="text-center">No answers yet.</h4>)} 
         </div>
         </main>
             ) : (                <>
                    {localStorage.setItem('referring_url', window.location.href)}
                    <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page.</h4> 
                </>
         )}
           <hr />
           </div>
      )
      break;
      case "CategoryB1":
        return (
          <div>
          {loggedIn ? (
         <main>
             <div>              
         {questionLoading ? (
             <Spinner className="spinner" animation="grow" />
         ) : (
             <>
             {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
             <h3 className="text-center">Your answers for {quiz.quizTitle}.</h3>
             </>
         )}
           </div>
         <div>
         {userData && me.userResult.length
         ? (
         <>
           <hr />
             <ResultsRenderB1
             me={me}
             answers={me.userResult} 
             quizIdProp={quiz._id}
             quizObject={quiz}
             quizQuestion = {quiz.quizQuestion}
             />
         </>
         //peppercorn specific name change from Quizzes to Exercises and results to answers
           ) : (<h4 className="text-center">No answers yet.</h4>)} 
         </div>
         </main>
             ) : (                <>
                    {localStorage.setItem('referring_url', window.location.href)}
                    <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page.</h4> 
                </>
         )}
           <hr />
           </div>
      )
      break;      
      case "CategoryC":
        return (
          <div>
          {loggedIn ? (
         <main>
             <div>              
         {questionLoading ? (
             <Spinner className="spinner" animation="grow" />
         ) : (
             <>
             {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
             <h3 className="text-center">Your answers for {quiz.quizTitle}.</h3>
             </>
         )}
           </div>
         <div>
         {userData && me.userResult.length
         ? (
         <>
           <hr />
             <ResultsRenderC
             me={me}
             answers={me.userResult} 
             quizIdProp={quiz._id}
             quizObject={quiz}
             quizQuestion = {quiz.quizQuestion}
             />
         </>
         //peppercorn specific name change from Quizzes to Exercises and results to answers
           ) : (<h4 className="text-center">No answers yet.</h4>)} 
         </div>
         </main>
             ) : (     
                <>
                    {localStorage.setItem('referring_url', window.location.href)}
                    <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page.</h4> 
                </>
         )}
           <hr />
           </div>
      )
      break;
      case "CategoryC1":
        return (
          <div>
          {loggedIn ? (
         <main>
             <div>              
         {questionLoading ? (
             <Spinner className="spinner" animation="grow" />
         ) : (
             <>
             {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
             <h3 className="text-center">Your answers for {quiz.quizTitle}.</h3>
             </>
         )}
           </div>
         <div>
         {userData && me.userResult.length
         ? (
         <>
           <hr />
             <ResultsRenderC1
             me={me}
             answers={me.userResult} 
             quizIdProp={quiz._id}
             quizObject={quiz}
             quizQuestion = {quiz.quizQuestion}
             />
         </>
         //peppercorn specific name change from Quizzes to Exercises and results to answers
           ) : (<h4 className="text-center">No answers yet.</h4>)} 
         </div>
         </main>
             ) : (     
                <>
                    {localStorage.setItem('referring_url', window.location.href)}
                    <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page.</h4> 
                </>
         )}
           <hr />
           </div>
      )
      break;
      case "CategoryF":
        return (
          <div>
          {loggedIn ? (
         <main>
             <div>              
         {questionLoading ? (
             <Spinner className="spinner" animation="grow" />
         ) : (
             <>
             {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
             <h3 className="text-center">Your answers for {quiz.quizTitle}.</h3>
             </>
         )}
           </div>
         <div>
         {userData && me.userResult.length
         ? (
         <>
           <hr />
             <ResultsRenderF
             me={me}
             answers={me.userResult} 
             quizIdProp={quiz._id}
             quizObject={quiz}
             quizQuestion = {quiz.quizQuestion}
             />
         </>
         //peppercorn specific name change from Quizzes to Exercises and results to answers
           ) : (<h4 className="text-center">No answers yet.</h4>)} 
         </div>
         </main>
             ) : (                <>
                    {localStorage.setItem('referring_url', window.location.href)}
                    <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page.</h4> 
                </>
         )}
           <hr />
           </div>
      )
      break;
      case "CategoryG":
        return (
          <div>
          {loggedIn ? (
         <main>
             <div>              
         {questionLoading ? (
             <Spinner className="spinner" animation="grow" />
         ) : (
             <>
             {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
             <h3 className="text-center">Your answers for {quiz.quizTitle}.</h3>
             </>
         )}
           </div>
         <div>
         {userData && me.userResult.length
         ? (
         <>
           <hr />
             <ResultsRenderG
             me={me}
             answers={me.userResult} 
             quizIdProp={quiz._id}
             quizObject={quiz}
             quizQuestion = {quiz.quizQuestion}
             />
         </>
         //peppercorn specific name change from Quizzes to Exercises and results to answers
           ) : (<h4 className="text-center">No answers yet.</h4>)} 
         </div>
         </main>
             ) : (                <>
                    {localStorage.setItem('referring_url', window.location.href)}
                    <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page.</h4> 
                </>
         )}
           <hr />
           </div>
      )
      break;
      case "CategoryH":
        return (
          <div>
          {loggedIn ? (
         <main>
             <div>              
         {questionLoading ? (
             <Spinner className="spinner" animation="grow" />
         ) : (
             <>
             {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
             <h3 className="text-center">Your answers for {quiz.quizTitle}.</h3>
             </>
         )}
           </div>
         <div>
         {userData && me.userResult.length
         ? (
         <>
           <hr />
             <ResultsRenderH
             me={me}
             answers={me.userResult} 
             quizIdProp={quiz._id}
             quizObject={quiz}
             quizQuestion = {quiz.quizQuestion}
             />
         </>
         //peppercorn specific name change from Quizzes to Exercises and results to answers
           ) : (<h4 className="text-center">No answers yet.</h4>)} 
         </div>
         </main>
             ) : (                <>
                    {localStorage.setItem('referring_url', window.location.href)}
                    <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page.</h4> 
                </>
         )}
           <hr />
           </div>
      )
      break;
      default:
        return (
          <div>
          {loggedIn ? (
         <main>
             <div>              
         {questionLoading ? (
             <Spinner className="spinner" animation="grow" />
         ) : (
             <>
             {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
             <h3 className="text-center">Your answers for {quiz.quizTitle}.</h3>
             {/* <h4 className="text-center">There were {quiz.quizQuestion.length} questions.</h4> */}
             </>
         )}
           </div>
         <div>
         {userData && me.userResult.length
         ? (
         <>
           <hr />
             <ResultsRender
             me={me}
             answers={me.userResult} 
             quizIdProp={quiz._id}
             quizObject={quiz}
             quizQuestion = {quiz.quizQuestion}
             />
         </>
         //peppercorn specific name change from Quizzes to Exercises and results to answers
           ) : (<h4 className="text-center">No answers yet.</h4>)} 
         </div>
         </main>
             ) : (
              <>
              {localStorage.setItem('referring_url', window.location.href)}
              <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page.</h4> 
            </>
         )}
           <hr />
           </div>
      )
     };
};
export default Result;