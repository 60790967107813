import React, { useEffect }from 'react';
import { Spinner } from 'react-bootstrap';
import CloudSpinner from '../components/CloudSpinner';
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_QUIZZES, QUERY_ME } from '../utils/queries';
import QuizzesRender from '../components/QuizzesRender';
import { useQuizContext } from '../utils/GlobalState';
import { UPDATE_QUIZZES, USER_DATA } from '../utils/actions';
import { idbPromise } from '../utils/helpers';

const Quizzes = () => {
  const [state, dispatch] = useQuizContext();

  const { loading: quizLoading, data: quizData } = useQuery(GET_ALL_QUIZZES);
  const { loading: userLoading, data: userData } = useQuery(QUERY_ME);

  useEffect(() => {

    if (quizData) {
      console.log({quizData})
      dispatch({
        type: UPDATE_QUIZZES,
        quizzes: quizData.quizzes
      });
      quizData.quizzes.forEach(quiz => {
        idbPromise('quizzes', 'put', quiz);
      });
    } else if (!quizLoading) {
      idbPromise('quizzes','get').then(quizzes => {
        dispatch({
          type: UPDATE_QUIZZES,
          quizzes: quizzes
        });
      });
    };

    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me
      });
    idbPromise('userdata','put', userData.me);
    } else if (!userLoading) {
      idbPromise('userdata','get').then(userdata => {
        dispatch({
          type: USER_DATA,
          userdata: userdata
        });
      });
    }
    console.log('update')
  }, [userData, userLoading, quizData, quizLoading, dispatch ]);

  return (
    <div>
    <main>
      {/* <div>{quizLoading ? <Spinner className="spinner" animation="grow" /> : 
        <QuizzesRender />}</div> */}
      <div>{quizLoading ? <CloudSpinner /> : <QuizzesRender />}</div>
    </main>
    <hr />
    </div>
  );
};

export default Quizzes;