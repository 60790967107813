import React from 'react';

const ENFP = () => {
    return (
        <div>
<h1>ENFP</h1>

<p>
  <strong>
    Career Path Insights
  </strong>
    <ul>
      <li>At work, you are preoccupied with expressing yourself and benefiting others via your creativity. </li>
      <li>You are curious about the possibilities for yourself and others, and </li>
      <li>You approach your job with creativity and vision. </li>
      <li>You like working on creative or people-related challenges that require a unique, innovative solution.</li>
      <li>You are typically driven by your commitment to humanitarian causes and a desire to do something that reflects your ideals. </li>
      <li>You are very interested in assisting others in their personal development. </li>
      <li>You prefer professions that enable you to seek personal development and artistic expression.</li>
      <li>You despise monotony and want a variety of jobs and challenges. </li>
      <li>You prefer to be in charge of your own schedule and resent being burdened with unnecessary restrictions or trivial minutiae. </li>
      <li>You look for enjoyable, unique jobs that allow you to be creative and interact with others in an unstructured, supportive manner.</li>
      <li>Your ideal workplace is one that is casual and pleasant, with minimal limitations on creativity. </li>
      <li>Your ideal work allows you to follow your passions, fulfill your curiosity, and create solutions that help people in unique and inventive ways.</li>
    </ul>
  </p>
<p>
  <strong>
    Working in a Team
  </strong>
  <ul>
    <li>You are a passionate, active team member who is curious about new ideas. </li>
    <li>You love interacting with others and hearing their thoughts, especially if they are creative. </li>
    <li>You are open-minded, yet you have a strong sense of values and you search for the concepts and reasons underlying your colleagues' ideas. </li>
    <li>You aren't interested in following rules, so you’ll urge your teammates to think outside the box to come up with a solution that's all their own. </li>
    <li>You aim to inspire others to be innovative and discover their own unique voice.</li>
  </ul>
</p>
<p>
  <strong>
    Being a Leader
  </strong>
  <ul>
    <li>You are more concerned with connections and ideas than with tasks and may clash with task-oriented colleagues. </li>
    <li>You like the challenge of brainstorming project ideas and options and </li>
    <li>You are sometimes hesitant to commit to a course of action and go forward. </li>
    <li>You are hesitant to take on details and </li>
    <li>You are best suited to contribute to a team with your exceptional interpersonal abilities. </li>
    <li>You are enthusiastic about the group's purpose and </li>
    <li>You are typically effective at inspiring and encouraging others to use their skills.</li>
  </ul>
</p>
</div>
  )
}
export default ENFP
