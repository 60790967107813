import { useReducer } from 'react';

import { 
    UPDATE_MODULES,
    UPDATE_QUIZZES,
    UPDATE_QUESTIONS,
    USER_DATA,
 }
from '../utils/actions';

export const reducer = (state, action) => {

    switch (action.type) {
        // if action type value is the value of `UPDATE_QUIZZES`, return a new state object with an updated quizzes array
        case UPDATE_MODULES:
            return {
                ...state,
                modules: [...action.modules],
            };

        case UPDATE_QUIZZES:
            return {
                ...state,
                quizzes: [...action.quizzes],
            };

        case UPDATE_QUESTIONS:
            return {
                ...state,
                questions: [...state.questions, action.quiz],
            };

        case USER_DATA:
            return {
                ...state,
                userdata: action.userdata,
            };


        default:
            return state ? state : '';
    }
};

export function useQuizReducer(initialState) {
    return useReducer(reducer, initialState);
}