import React from 'react';
import { Link, useParams} from "react-router-dom";
import {  Card, Badge, Row, Col, CardDeck, ListGroup} from 'react-bootstrap';
import { useQuizContext } from '../utils/GlobalState';
import Auth from '../utils/auth';

const QuizzesRender = () => {
  const { moduleNum } = useParams();
  const [state] = useQuizContext();
  const { quizzes, userdata } = state;
  let completed = userdata.completed;

  const handleRender = (StringCompare, moduleNum) => {
  let targetValue = 9272022;
  const parsed = parseInt(StringCompare, 10);
  const number = parseInt(moduleNum, 10);
  // console.log(StringCompare, parsed);
  let personalityFalsyValue = (parsed !== targetValue); // will be true if NOT a personality quiz  - then render
  let moduleFalsyValue = (parsed === number); // will be true if exercise belongs to the chosen module - then render
  let falsyValue = personalityFalsyValue && moduleFalsyValue //if either is false the output should be false and not rendered
  console.log({parsed}, {number}, {personalityFalsyValue}, {moduleFalsyValue}, {falsyValue});
  return falsyValue
  }

const handleComplete = (number) => {
  if (!userdata.length) {
    return (
    // <Badge 
    //   className="pill-center mx-2 my-2"
    //   variant={"dark"}>
    //   {"logged in"}
    // </Badge>
    <> </>
    )
  }
  else {
  let completeForUser = completed.some(complete => complete === (number));

  return (
    <Badge 
      className="pill-center mx-2 my-2"
      variant={completeForUser ? "success" : "dark"}>
      {completeForUser ? "Complete" : "Incomplete"}
    </Badge>)
  }
};

  if (!quizzes.length) {
    //peppercorn specific name change from Quizzes to Exercises
    return <h3>There are no quizzes in this library!</h3>;
  }

  const loggedIn = Auth.loggedIn();

  return (
    <div>
       <hr />
      <Row>
      <ListGroup className="col-auto" variant="flush">
        {quizzes && quizzes.map((quiz) => (
          <div key={quiz.quizNumber}>
            { handleRender(quiz.quizPoster, moduleNum)? (<> 
              {loggedIn
                ? (handleComplete(quiz.quizNumber))
                : (<Badge className="pill-center mx-2 my-2" variant="custom">Login Required</Badge>
                )} 
                <Link to={`/quiz/${quiz.quizNumber}`}>
                <ListGroup.Item 
                variant="custom" 
                className="list-group-item-custom mx-2 my-2" 
                key={quiz.quizNumber}
                >
                <small className="text-muted">{quiz.quizTitle}</small>
                <br />
                <small className="text-muted">{quiz.quizOverview[0]}</small>
                </ListGroup.Item>
                </Link>
            </>):(<> </>)}

          </div>             
        ))}
      </ListGroup>
      </Row>
    </div>
  );
};

export default QuizzesRender;
