import React,{ useState } from 'react';
import { Link } from "react-router-dom";
import { Alert, Badge ,Card, Row, Col, CardDeck, Button } from 'react-bootstrap';
import Auth from '../../utils/auth';
import { useQuizContext } from '../../utils/GlobalState';

const JournalStyle ={
  marginRight: "0px",
  marginLeft: "0px"
};

const AllResultsRenderC1 = (props) => {
  const {
    answers,
    quizIdProp,
    quizObject,
    quizQuestion,
    quizNum,
    username 
  } = props;
//**** answers holds userResult - an object array of all results for quizzes taken by user thus is me.userResult
//**** quiz hold the _id of the current quiz taken by user from where they are passed to this page thus is quiz._id
//**** quizQuestion is an object array if questions based on the Question model.
const [state] = useQuizContext();
const [show, setShow] = useState(true);
// console.log({quizIdProp});
// console.log({quizQuestion});
// console.log({quizObject});
// Lookup if the userResult Object array exists for this quiz

//const answerLookup is a Boolean, and true if the quiz result exists
  const answerLookup = answers.map((element) => element.quizId[0]).map(e => e._id).includes(`${quizIdProp}`);
  
// Create array with results and result date to render for quiz. 
  let results = [];
  let resultDate = [];
  if (answerLookup) {
    answers.map((answer) => {
      answer.quizId.map((id) => {
        if(id._id !== `${quizIdProp}`) {
          return false;
        }
        results.push(answer)
        resultDate.push(new Date(parseInt(answer.resultReleaseDate)).toLocaleDateString())
        return (results, resultDate);
      })
      return (results, resultDate);
    })
  }

//parsing the results array to get a JSON Object
  let userResultTitleJSONObject =[];
  if (answerLookup) {
    results.map((answer) => {
      answer.userResultTitle.map((urt) => {
        const urtJson = JSON.parse(urt)
        // console.log("urtJson", urtJson)
        userResultTitleJSONObject.push(urtJson)
        return userResultTitleJSONObject
        })
      return userResultTitleJSONObject
      });
    };
  // console.log("userResultTitleJSONObject",userResultTitleJSONObject);

  let userResultWeightJSONObject =[];
  if (answerLookup) {
    results.map((answer) => {
      answer.userResultWeight.map((urw) => {
        const urtJsonW = JSON.parse(urw);
        // console.log("urtJsonW", urtJsonW);
        userResultWeightJSONObject.push(urtJsonW);
        return userResultWeightJSONObject ;
        })
      return userResultWeightJSONObject;
      });
    };
  // console.log("userResultWeightJSONObject",userResultWeightJSONObject);

// combining  both these parsed JSON Object arrays into 1 New Array while removing duplicates
 const mergeByProperty = (target, source, prop) => {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement[prop] === targetElement[prop];
      })
      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
      target._id = `${(Math.floor(Math.random() * 100))}`;
      //urtJsonW._id = "smith"; way to add id value to array to map with React unique key;
    })
  }

//parse/stringify, was the best way to copy without overwriting original array
let length = userResultTitleJSONObject.length;
let newUserResultJSONObject=[];
for (let i = 0; i < length; i += 1) {
  const target = JSON.parse(JSON.stringify(userResultTitleJSONObject[i]));
  const source = JSON.parse(JSON.stringify(userResultWeightJSONObject[i]));
  mergeByProperty(target, source, 'Question');
  // console.log("target", target);
  //extract and array of the weights
  let scoreArray = target.map(a => a.Weight);
  // console.log("scoreArray", scoreArray);
  //total all the integers in the weights array
  let attemptScore = scoreArray.reduce(function(a, b) { return a + b; }, 0);
  // console.log("attemptScore", attemptScore);
  //Create and add attempScore key/value to object target
  target.attemptScore = attemptScore;
  newUserResultJSONObject.push(target);
};
// console.log("newUserResultJSONObject", newUserResultJSONObject)
//example newUserResultJSONObject : Array(2)
//0: {Question: 51, Choice: "Washington DC", Weight: 1}
//1: {Question: 52, Choice: "Sacramento", Weight: 1}
//attemptScore: 2
//_id: "71"

// parsing only the last attempt from newUserResultJSONObject
// let lastUserResult = newUserResultJSONObject[newUserResultJSONObject.length - 1]
// // console.log("lastUserResult", lastUserResult)

// parsing only the last attempt time 
// let lastUserResultDate = resultDate[resultDate.length - 1]|| "None"
// let RenderlastUserResultDate = lastUserResultDate
// console.log("***************lastUserResultDate", lastUserResultDate)

//if no data return no results
  if (!answers || !quizQuestion || !quizIdProp || !answerLookup) {
//peppercorn specific name change from Quizzes to Exercises and results to answers
    return <p>No journal entries yet.</p>;
  }
  const loggedIn = Auth.loggedIn();

  return (
    <div>
      {loggedIn && answerLookup ? (
       <>
        {newUserResultJSONObject.map((thisUserResult, i) => (
         <div key={thisUserResult._id}>
                <div>
                <Row style={JournalStyle}>
                    {thisUserResult.map((newUserResultJSONObject2, j) => (
                      <p key={j}>

                          {resultDate[j]}: {newUserResultJSONObject2.Choice.slice(0,300)}...

                      </p>
                    ))}
                </Row>
                </div>
              <br/>
         </div>
        ))}
       </>
        ) : (<>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </>
      )}
    </div>
  );
};

export default AllResultsRenderC1;