import React from 'react';
import { Link } from "react-router-dom";
import {  Badge ,Card, Row, Col, CardDeck, Button } from 'react-bootstrap';
import Auth from '../../utils/auth';
import { useQuizContext } from '../../utils/GlobalState';

const ResultCard = {
  marginBottom: "10px"
};

const ResultsRender = (props) => {
  const {
    me,
    answers,
    quizIdProp,
    quizObject,
    quizQuestion, 
  } = props;
//**** answers holds userResult - an object array of all results for quizzes taken by user thus is me.userResult
//**** quizIdProp hold the _id of the current quiz taken by user from where they are passed to this page thus is quiz._id
//**** quizQuestion is an object array if questions based on the Question model.
const [state] = useQuizContext();

// Lookup if the userResult Object array exists for this quiz
//const answerLookup is a Boolean, and true if the quiz result exists
  const answerLookup = answers.map((element) => element.quizId[0]).map(e => e._id).includes(`${quizIdProp}`);
  
  // Create array with results to render for quiz. 
  let results = [];
  if (answerLookup) {
    answers.map((answer) => {
      answer.quizId.map((id) => {
        if(id._id !== `${quizIdProp}`) {
          // console.log('not pushed', id) // This is not pushed
          return false;
        }
        // console.log('pushed', id) // This is pushed to results array
        results.push(answer)
        return console.log({results});
      })
      return console.log({results});
    })
  }

//parsing the results array to get a JSON Object
  let userResultTitleJSONObject =[];
  if (answerLookup) {
    results.map((answer) => {
      answer.userResultTitle.map((urt) => {
        const urtJson = JSON.parse(urt)
        console.log("urtJson", urtJson)
        userResultTitleJSONObject.push(urtJson)
        return console.log({userResultTitleJSONObject});
        })
        return console.log({userResultTitleJSONObject});
      });
    };

  let userResultWeightJSONObject =[];
  if (answerLookup) {
    results.map((answer) => {
      answer.userResultWeight.map((urw) => {
        const urtJsonW = JSON.parse(urw);
        console.log("urtJsonW", urtJsonW);
        userResultWeightJSONObject.push(urtJsonW);
        return console.log({userResultWeightJSONObject});
        })
        return console.log({userResultWeightJSONObject});
      });
    };
  console.log("userResultWeightJSONObject",userResultWeightJSONObject);

// combining  both these parsed JSON Object arrays into 1 New Array while removing duplicates
 const mergeByProperty = (target, source, prop) => {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement[prop] === targetElement[prop];
      })
      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
      target._id = `${(Math.floor(Math.random() * 100))}`;
      //urtJsonW._id = "smith"; way to add id value to array to map with React unique key;
    })
  }

//parse/stringify, was the best way to copy without overwriting original array
let length = userResultTitleJSONObject.length;
let newUserResultJSONObject=[];
for (let i = 0; i < length; i += 1) {
  const target = JSON.parse(JSON.stringify(userResultTitleJSONObject[i]));
  const source = JSON.parse(JSON.stringify(userResultWeightJSONObject[i]));
  mergeByProperty(target, source, 'Question');
  console.log("target", target);
  let scoreArray = target.map(a => a.Weight);
  console.log("scoreArray", scoreArray);
  let attemptScore = scoreArray.reduce(function(a, b) { return a + b; }, 0);
  console.log("attemptScore", attemptScore);
  target.attemptScore = attemptScore;
  newUserResultJSONObject.push(target);
};
console.log("newUserResultJSONObject", newUserResultJSONObject)


//if no data return no results
  if (!answers || !quizQuestion || !quizIdProp || !answerLookup) {
     //peppercorn specific name change from Quizzes to Exercises and results to answers
    return <h1>There are no answers for this Exercise!</h1>;
  }
  const loggedIn = Auth.loggedIn();

  return (
    <div>
      {loggedIn && answerLookup ? (
       <>
        <Row className="justify-content-md-center justify-content-sm-center justify-content-xs-center">
        {newUserResultJSONObject.map((newUserResultJSONObject1, i) => (
        //  <Col key={newUserResultJSONObject1._id}>
        //       <CardDeck className="my-2"> 
        //       <Card className="resultcard">
        <Col key={newUserResultJSONObject1._id} md={{ span: 12, 
          //  offset: 1
           }}>
              <CardDeck style={ResultCard}>
                 <Card className="searchcard"> 
              <Badge className="pill-center mx-2 my-2" variant="custom">Attempt {i+1}</Badge>
              <Badge className="pill-center mx-2 my-2" variant="custom">Score {newUserResultJSONObject1.attemptScore}</Badge>
              {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
              {/* <Card.Title className="text-center my-2">Your Answer</Card.Title> */}
              <Row>
              <Col>
              <Card.Body>
                <div>
                <Row>
                    <Col>
                    {newUserResultJSONObject1.map((newUserResultJSONObject2, j) => (
                      <p key={j}>
                          <li >
                          Question: {newUserResultJSONObject2.Question},
                          </li>

                          <Button variant={newUserResultJSONObject2.Weight !== 0 ? 'success' : 'danger'}>
                          Your Answer: {newUserResultJSONObject2.Choice}
                          </Button>
                          <Button variant='custom'>
                          Your Answer's score: {newUserResultJSONObject2.Weight}
                          </Button>

                      </p>
                    ))}
                    </Col>
                </Row>

                </div>
          
              </Card.Body> 
              </Col>
              </Row>   
              </Card>
             </CardDeck>

         </Col>
       ))}
        </Row>
        <Button> Download Result </Button>
       </>
        ) : (<>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </>
      )}
    </div>
  );
};

export default ResultsRender;
