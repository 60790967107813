// React packages and UI
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
// Global State
import { useQuizContext } from '../utils/GlobalState';
import { idbPromise } from '../utils/helpers';
import { USER_DATA, UPDATE_QUESTIONS } from '../utils/actions';
// Components
import QuestionsRenderA from '../components/QuestionsRender/QuestionsRenderA'; // radio selection
import QuestionsRenderB from '../components/QuestionsRender/QuestionsRenderB'; // personality
import QuestionsRenderB1 from '../components/QuestionsRender/QuestionsRenderB1'; // select if personality comments apply
import QuestionsRenderC from '../components/QuestionsRender/QuestionsRenderC'; // free response
import QuestionsRenderC1 from '../components/QuestionsRender/QuestionsRenderC1'; // Journal
import QuestionsRenderF from '../components/QuestionsRender/QuestionsRenderF'; //drop down select
import QuestionsRenderG from '../components/QuestionsRender/QuestionsRenderG'; //multichoice select
import QuestionsRenderH from '../components/QuestionsRender/QuestionsRenderH'; //autocomplete
import QuestionsRender from '../components/QuestionsRender/QuestionsRender';
import Auth from '../utils/auth';
// GraphQL API
import { GET_SELECTED_QUIZ, QUERY_ME } from '../utils/queries';
import { useQuery, useMutation } from '@apollo/react-hooks';
// icons
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Question = () => {
    const { quizNum } = useParams();
    const [state, dispatch] = useQuizContext();

    const { loading: loadingUser, data: userData } = useQuery(QUERY_ME);
    const { loading: loadingData, data: questionData } = useQuery(GET_SELECTED_QUIZ, {
        variables: { quizNum: Number(quizNum) }
      });

    const quiz = questionData?.quiz || [];
    const me = userData?.me || [];
    let quizId = quiz._id;
    console.log(quizId);
    console.log(me.username);

    useEffect(() => {
        if (questionData) {
          dispatch({
            type: UPDATE_QUESTIONS,
            questions: quiz
          });
        quiz.quizQuestion.forEach(quiz => {
            idbPromise('questions', 'put', quiz);
          });
        } else if (!loadingData) {
          idbPromise('questions','get').then(questions => {
            dispatch({
              type: UPDATE_QUESTIONS,
              questions: questions
            });
          });
        };
    
        if (userData) {
          dispatch({
            type: USER_DATA,
            userdata: userData.me
          });
        idbPromise('userdata','put', userData.me);
        } else if (!loadingUser) {
          idbPromise('userdata','get').then(userdata => {
            dispatch({
              type: USER_DATA,
              userdata: userdata
            });
          });
        }
      }, [userData, loadingUser, questionData, loadingData, dispatch ]);

    const loggedIn = Auth.loggedIn();

   switch(quiz.quizCategory) {
      case "CategoryA":
        return (
          <div>
               {loggedIn ? (
              <main>
                  <div>              
              {loadingData ? (
                  <Spinner className="spinner" animation="grow" />
              ) : (
                  <>
                  <h3 className="text-center"> {quiz.quizTitle}<img src="https://discovery-journey.s3.us-east-2.amazonaws.com/icons/4aexercise.jpg"
                    alt="Exercise Icon" class="quiz-icon"></img></h3>
                  <h4 className="text-center">There are {quiz.quizQuestion.length} questions.</h4>
                  </>
              )}
                </div>
              <div>
              {questionData 
              ? (
              <>
                <hr />
                  <QuestionsRenderA
                  questions={quiz.quizQuestion} 
                  quizNumber={quizNum}
                  quizId={quiz._id}
                  username={me.username}
                  />
              </>
          ) : ''} 
              </div>
          </main>
          ) : (
          <>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </>
          )}
             <hr />
       </div>
      )
      break;
      case "CategoryB":
        return (
          <div>
               {loggedIn ? (
              <main>
                  <div>              
              {loadingData ? (
                  <Spinner className="spinner" animation="grow" />
              ) : (
                  <>
                  <h3 className="text-center"> {quiz.quizTitle}<img src="https://discovery-journey.s3.us-east-2.amazonaws.com/icons/4aexercise.jpg"
                    alt="Exercise Icon" class="quiz-icon"></img></h3>
                  <h4 className="text-center">There are {quiz.quizQuestion.length} questions.</h4>
                  </>
              )}
                </div>
              <div>
              {questionData 
              ? (
              <>
                <hr />
                  <QuestionsRenderB
                  questions={quiz.quizQuestion} 
                  quizNumber={quizNum}
                  quizId={quiz._id}
                  username={me.username}
                  />
              </>
          ) : ''} 
              </div>
          </main>
          ) : (<>
          <hr /><>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </></>
          )}
             <hr />
        </div>
      )
      break;
      case "CategoryB1":
        return (
          <div>
               {loggedIn ? (
              <main>
                  <div>              
              {loadingData ? (
                  <Spinner className="spinner" animation="grow" />
              ) : (
                  <>
                  <h3 className="text-center"> {quiz.quizTitle}<img src="https://discovery-journey.s3.us-east-2.amazonaws.com/icons/4aexercise.jpg"
                    alt="Exercise Icon" class="quiz-icon"></img></h3>
                  <h4 className="text-center">There are {quiz.quizQuestion.length} questions.</h4>
                  </>
              )}
                </div>
              <div>
              {questionData 
              ? (
              <>
                <hr />
                  <QuestionsRenderB1
                  questions={quiz.quizQuestion} 
                  quizNumber={quizNum}
                  quizId={quiz._id}
                  username={me.username}
                  />
              </>
          ) : ''} 
              </div>
          </main>
          ) : (<>
          <hr /><>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </></>
          )}
             <hr />
        </div>
      )
      break;
      case "CategoryC":
        return (
          <div>
               {loggedIn ? (
              <main>
                  <div>              
              {loadingData ? (
                  <Spinner className="spinner" animation="grow" />
              ) : (
                  <>
                  <h3 className="text-center"> {quiz.quizTitle}<img src="https://discovery-journey.s3.us-east-2.amazonaws.com/icons/4aexercise.jpg"
        alt="Exercise Icon" class="quiz-icon"></img></h3>
                  <h4 className="text-center">There are {quiz.quizQuestion.length} questions.</h4>
                  </>
              )}
                </div>
              <div>
              {questionData 
              ? (
              <>
                <hr />
                  <QuestionsRenderC
                  questions={quiz.quizQuestion} 
                  quizNumber={quizNum}
                  quizId={quiz._id}
                  username={me.username}
                  />
              </>
          ) : ''} 
              </div>
          </main>
          ) : (<>
          <hr /><>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </></>
          )}
             <hr />
        </div>
      )
      break;
      case "CategoryC1":
        return (
          <div>
               {loggedIn ? (
              <main>
                  <div>              
              {loadingData ? (
                  <Spinner className="spinner" animation="grow" />
              ) : (
                  <>
                  <h3 className="text-center">    
                    {quiz.quizTitle}              
                    <img src="https://discovery-journey.s3.us-east-2.amazonaws.com/icons/5ajournal.png"
                  alt="Journal Icon" class="quiz-icon">
                    </img>
                    {/* Welcome to Journal:  */}
                  </h3>
                  {/* <h4 className="text-center">There are {quiz.quizQuestion.length} questions.</h4> */}
                  </>
              )}
                </div>
              <div>
              {questionData 
              ? (
              <>
                <hr />
                  <QuestionsRenderC1
                  questions={quiz.quizQuestion} 
                  quizNumber={quizNum}
                  quizId={quiz._id}
                  username={me.username}
                  />
              </>
          ) : ''} 
              </div>
          </main>
          ) : (<>
          <hr /><>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </></>
          )}
             <hr />
        </div>
      )
      break;
      case "CategoryF":
        return (
          <div>
               {loggedIn ? (
              <main>
                  <div>              
              {loadingData ? (
                  <Spinner className="spinner" animation="grow" />
              ) : (
                  <>
                    <h3 className="text-center"> {quiz.quizTitle}<img src="https://discovery-journey.s3.us-east-2.amazonaws.com/icons/4aexercise.jpg"
        alt="Exercise Icon" class="quiz-icon"></img></h3>
                  <h4 className="text-center">There are {quiz.quizQuestion.length} questions.</h4>
                  </>
              )}
                </div>
              <div>
              {questionData 
              ? (
              <>
                <hr />
                  <QuestionsRenderF
                  questions={quiz.quizQuestion} 
                  quizNumber={quizNum}
                  quizId={quiz._id}
                  username={me.username}
                  />
              </>
          ) : ''} 
              </div>
          </main>
          ) : (<>
          <hr /><>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </></>
          )}
             <hr />
        </div>
      )
      break;
      case "CategoryG":
        return (
          <div>
               {loggedIn ? (
              <main>
                  <div>              
              {loadingData ? (
                  <Spinner className="spinner" animation="grow" />
              ) : (
                  <>
<h3 className="text-center"> {quiz.quizTitle}<img src="https://discovery-journey.s3.us-east-2.amazonaws.com/icons/4aexercise.jpg"
        alt="Exercise Icon" class="quiz-icon"></img></h3>
                  <h4 className="text-center">There are {quiz.quizQuestion.length} questions.</h4>
                  </>
              )}
                </div>
              <div>
              {questionData 
              ? (
              <>
                <hr />
                  <QuestionsRenderG
                  questions={quiz.quizQuestion} 
                  quizNumber={quizNum}
                  quizId={quiz._id}
                  username={me.username}
                  />
              </>
          ) : ''} 
              </div>
          </main>
          ) : (<>
          <hr /><>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </></>
          )}
             <hr />
        </div>
      )
      break;
      case "CategoryH":
        return (
          <div>
               {loggedIn ? (
              <main>
                  <div>              
              {loadingData ? (
                  <Spinner className="spinner" animation="grow" />
              ) : (
                  <>
<h3 className="text-center"> {quiz.quizTitle}<img src="https://discovery-journey.s3.us-east-2.amazonaws.com/icons/4aexercise.jpg"
        alt="Exercise Icon" class="quiz-icon"></img></h3>
                  <h4 className="text-center">There are {quiz.quizQuestion.length} questions.</h4>
                  </>
              )}
                </div>
              <div>
              {questionData 
              ? (
              <>
                <hr />
                  <QuestionsRenderH
                  questions={quiz.quizQuestion} 
                  quizNumber={quizNum}
                  quizId={quiz._id}
                  username={me.username}
                  />
              </>
          ) : ''} 
              </div>
          </main>
          ) : (<>
          <hr /><>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </></>
          )}
             <hr />
        </div>
      )
      break;
      default:
        return (
          <div>
               {loggedIn ? (
              <main>
                  <div>              
              {loadingData ? (
                  <Spinner className="spinner" animation="grow" />
              ) : (
                  <>
<h3 className="text-center"> {quiz.quizTitle}<img src="https://discovery-journey.s3.us-east-2.amazonaws.com/icons/4aexercise.jpg"
        alt="Exercise Icon" class="quiz-icon"></img></h3>
                  <h4 className="text-center">There are {quiz.quizQuestion.length} questions.</h4>
                  <h4 className="text-center">The quiz type is  {quiz.quizCategory} </h4>
                  </>
              )}
                </div>
              <div>
              {questionData 
              ? (
              <>
                <hr />
                  <QuestionsRender
                  questions={quiz.quizQuestion} 
                  quizNumber={quizNum}
                  quizId={quiz._id}
                  username={me.username}
                  />
              </>
          ) : ''} 
              </div>
          </main>
          ) : (<>
          <hr /><>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </></>
          )}
             <hr />
        </div>
      )
     }
};

export default Question;
