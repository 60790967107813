import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Form, Row, Card, CardDeck, Col} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuizContext } from '../../utils/GlobalState';
import { ADD_RESULT } from '../../utils/mutations';
import { useHistory } from 'react-router-dom';
//if name="userResultTitle", is hardcoded, then only one choice is possible.
const RadioStyle = {
  width: "100px"
};

const LabelStyle = {
  fontSize: "0.7rem"
};

const QuestionsRender = (props) => {
  const [buttonSubmit, setButtonSubmit] = useState(false);
    const [addResult, { error }] = useMutation(ADD_RESULT);
    const {
        questions,
        quizNumber,
        quizId,
        username
      } = props;
    const [state] = useQuizContext(); 

const dbName = state.userdata;


/******** hardcoding defaultValues */
const defaultValues = {
   userResultTitle: ['defaultTitle'], userResultWeight: ['defaultOverview'], quizId: quizId
};

  const { register, handleSubmit, errors } = useForm(defaultValues);
  const onSubmit = async data => {
    let urt = [];
    let urw = []
    for (let jVal of Object.keys(data)) {
      var iVal = data[jVal];
      let Choice = questions[jVal].questionResponseChoices[iVal];
      let Weight = questions[jVal].questionResponseWeights[iVal];
      let Question = questions[jVal].questionNumber;
      urt.push({ Question, Choice });
      urw.push({ Question, Weight });
      console.log("jVal", jVal, "iVal", iVal);
  };

  console.log("urt", urt, "urw", urw);

    console.log("data", data);
    let title = JSON.stringify(urt);
    let weight = JSON.stringify(urw);
    const dataJSON = { userResultTitle:[ title ], userResultWeight: [ weight], quizId: quizId };
    console.log(dataJSON);
    try {
        // execute addResultTitle to the  mutation and pass in variable data from formState
        //upon success, destructure data object from response of mutation and console log
        const result  = await addResult({
          variables: { ...dataJSON }
        });
      } catch (e) {
        console.error(e);
      }
      setButtonSubmit(true);
  }; 


  return (
  <div>
      <Row className="justify-content-md-center justify-content-sm-center justify-content-xs-center">
       <Form onSubmit={handleSubmit(onSubmit)} >
      {questions &&  questions.map((question, j) => (
            <Col key={questions[j]._id}>
            <CardDeck>
                  <Card className="text-center quizcard" >
                    <Card.Body>
                      <Card.Title>
                      {questions[j].questionTitle}
                      </Card.Title>
                        {questions[j].questionResponseChoices.map((choice, i) => (
                        <Row key={questions[j].questionResponseChoices[i]}>
                        <label className="text-center radio has-background-light" style={LabelStyle} >
                        <Form.Check inline type="radio" name={j} value={i} style={RadioStyle} ref={register({ required: true })}/>
                        {choice}
                        </label>
                       </Row> 
                        ))}
                    </Card.Body>
                    </Card>
            </CardDeck>
            <br />
        </Col>
        ))}
        <Button 
            className='btn d-block w-75 mx-auto' 
            type='submit'
            variant='success'
            disabled = {buttonSubmit}
        >{buttonSubmit ? 'Submitted!! ✈ ' : 'Submit'}
        </Button>
        <a href={`/quiz/${quizNumber}/result/:${username}?`} className="inherit">Click for result</a>
        </Form>
        </Row>
    </div>
  )
}
export default QuestionsRender