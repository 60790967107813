import React, { useState, Fragment } from "react";
import {useField, splitFormProps } from "react-form";

const SelectboxStyle = {
  borderColor: "transparent",
  color: "transparent",
}


const AutocompleteForm = (props) => {
  const [field, fieldOptions, { suggestions }] = splitFormProps(props);

  const {
    value = "",
    setValue,
    meta: { error, isTouched }
  } = useField(field, fieldOptions);

  const [activeSuggestion, setactiveSuggestion] = useState(0);
  const [filteredSuggestions, setfilteredSuggestions] = useState([]);
  const [showSuggestions, setshowSuggestions] = useState(false);
  const [input, setInput] = useState(0)

  const onChange = (e) => {
    if (value === "=") 
    {
    // show all suggestions
    setfilteredSuggestions(suggestions)
    } 
  else 
    {
    // Filter out suggestions that don't contain the user's input
    // setfilteredSuggestions(suggestions)
    const filterSugg = suggestions.filter(
      (suggestion, i) => 
      suggestion.label.toLowerCase().indexOf(value.toLowerCase()) > -1
    )
    setfilteredSuggestions(filterSugg)
    }
      setactiveSuggestion(0);
      setshowSuggestions(true);
      setValue(e.target.value);

  }

  const onClick = e => {
      setactiveSuggestion(0);
      setfilteredSuggestions([]);
      setshowSuggestions();

      setValue(e.target.innerText)
  };

  const onKeyDown = e => {

    // User pressed the enter key
    if (e.keyCode === 13) {
        setactiveSuggestion(0);
        setshowSuggestions(false);
        setValue(filteredSuggestions[activeSuggestion])
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setactiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if ((activeSuggestion - 1) === filteredSuggestions.length) {
        return;
      }
      setactiveSuggestion(activeSuggestion + 1);
    }
  };
  

    let suggestionsListComponent;

    if (showSuggestions && value) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={index} onClick={onClick}>
                  {suggestion.label}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }

    
    return (
      <>
      
      <Fragment>
        <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value}
        />
   
        <select 
          // {...rest} 
          style={SelectboxStyle} 
          value={value} 
          onChange={onChange}>
        </select>
        {suggestionsListComponent}
      </Fragment>
      {isTouched && error ? <em>{error}</em> : null}

      </>
    );
    
  
}

  
export default AutocompleteForm;
  