import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {  Alert, Badge ,Card, Row, Col, CardDeck, Button } from 'react-bootstrap';
import Auth from '../../utils/auth';
import { useQuizContext } from '../../utils/GlobalState';
import GenerateDoc from './GenerateDoc';

const ResultCard = {
  marginBottom: "10px"
};

const ResultsRenderB1 = (props) => {
  const {
    me,
    answers,
    quizIdProp,
    quizObject,
    quizQuestion, 
  } = props;
//**** answers holds userResult - an object array of all results for quizzes taken by user thus is me.userResult
//**** quizIdProp hold the _id of the current quiz taken by user from where they are passed to this page thus is quiz._id
//**** quizQuestion is an object array if questions based on the Question model.
const [state] = useQuizContext();
const [show, setShow] = useState(true);
console.log({quizIdProp});
console.log({quizQuestion});
console.log({quizObject});
// Lookup if the userResult Object array exists for this quiz
//const answerLookup is a Boolean, and true if the quiz result exists
  const answerLookup = answers.map((element) => element.quizId[0]).map(e => e._id).includes(`${quizIdProp}`);
  
  // Create array with results to render for quiz. 
  let results = [];
  if (answerLookup) {
    answers.map((answer) => {
      answer.quizId.map((id) => {
        if(id._id !== `${quizIdProp}`) {
          return false;
        }
        results.push(answer)
        return console.log({results});
      })
      return console.log({results});
    })
  }

//parsing the results array to get a JSON Object
  let userResultTitleJSONObject =[];
  if (answerLookup) {
    results.map((answer) => {
      answer.userResultTitle.map((urt) => {
        const urtJson = JSON.parse(urt)
        console.log("urtJson", urtJson)
        userResultTitleJSONObject.push(urtJson)
        return console.log({userResultTitleJSONObject});
        })
      return console.log({userResultTitleJSONObject});
      });
    };
  console.log("userResultTitleJSONObject",userResultTitleJSONObject);

  let userResultWeightJSONObject =[];
  if (answerLookup) {
    results.map((answer) => {
      answer.userResultWeight.map((urw) => {
        const urtJsonW = JSON.parse(urw);
        console.log("urtJsonW", urtJsonW);
        userResultWeightJSONObject.push(urtJsonW);
        return console.log({userResultWeightJSONObject});
        })
      return console.log({userResultWeightJSONObject});
      });
    };
  console.log("userResultWeightJSONObject",userResultWeightJSONObject);

// combining  both these parsed JSON Object arrays into 1 New Array while removing duplicates
 const mergeByProperty = (target, source, prop) => {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement[prop] === targetElement[prop];
      })
      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
      target._id = `${(Math.floor(Math.random() * 100))}`;
      //urtJsonW._id = "smith"; way to add id value to array to map with React unique key;
    })
  }

//parse/stringify, was the best way to copy without overwriting original array
let length = userResultTitleJSONObject.length;
let newUserResultJSONObject=[];
for (let i = 0; i < length; i += 1) {
  const target = JSON.parse(JSON.stringify(userResultTitleJSONObject[i]));
  const source = JSON.parse(JSON.stringify(userResultWeightJSONObject[i]));
  mergeByProperty(target, source, 'Question');
  console.log("target", target);
  //extract and array of the weights
  let scoreArray = target.map(a => a.Weight);
  console.log("scoreArray", scoreArray);
  //total all the integers in the weights array
  let attemptScore = scoreArray.reduce(function(a, b) { return a + b; }, 0);
  console.log("attemptScore", attemptScore);
  //Create and add attempScore key/value to object target
  target.attemptScore = attemptScore;
  newUserResultJSONObject.push(target);
};
console.log("newUserResultJSONObject", newUserResultJSONObject)
//example newUserResultJSONObject : Array(2)
//0: {Question: 51, Choice: "Washington DC", Weight: 1}
//1: {Question: 52, Choice: "Sacramento", Weight: 1}
//attemptScore: 2
//_id: "71"


const handleRender = (StringCompare) => { //output is true if answer is "yes"
  let text1= "yes"
  let text2 = StringCompare 
  let result = text1.localeCompare(text2);// result will be 0 only if both strings are the same
  let targetValue = 0;
  let personalityTrueValue = (result === targetValue); // will be true if preference answer is yes  - then render
  console.log({personalityTrueValue});
  return personalityTrueValue
  }

//if no data return no results
  if (!answers || !quizQuestion || !quizIdProp || !answerLookup) {
//peppercorn specific name change from Quizzes to Exercises and results to answers
    return <h1>There are no answers for this Exercise!</h1>;
  }
  const loggedIn = Auth.loggedIn();

  return (
    <div>
      {loggedIn && answerLookup ? (
       <>
        <div className="text-right">
          {/* Removing feature to hide or show answers in this case
          {!show && <Button  onClick={() => setShow(true)} variant="info">Show Answers</Button>}
          {show && <Button onClick={() => setShow(false)} variant="outline-info">Close Answers</Button>} */}
        </div>
        <Row className="justify-content-md-center justify-content-sm-center justify-content-xs-center">
        {newUserResultJSONObject.map((newUserResultJSONObject1, i) => (
        <Col key={newUserResultJSONObject1._id} md={{ span: 12, 
          //  offset: 1
           }}>
            <CardDeck style={ResultCard}>
              <Card className="searchcard"> 
                 <Badge className="pill-center mx-2 my-2" variant="custom">Attempt {i+1}</Badge>
              {/* <Badge className="pill-center mx-2 my-2" variant="custom">Score {newUserResultJSONObject1.attemptScore}</Badge> */}
              {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
              {/* <Card.Title className="text-center my-2">Your Answer</Card.Title> */}
              <Row>
              <Col>
              <Card.Body>
                <div>
                <Row>
                    <Col>
                    {newUserResultJSONObject1.map((newUserResultJSONObject2, j) => (
                      <p key={j}>
                        { handleRender(newUserResultJSONObject2.Choice)?
                          <li >
                            {/* {newUserResultJSONObject2.Question} */}
                            {quizQuestion[j].questionTitle}
                          </li>
                          :
                          <> </>
                        }                        
                      </p>
                    ))}
                    </Col>
                </Row>

                </div>
          
              </Card.Body> 
              </Col>
              </Row>   
              </Card>
             </CardDeck>

         </Col>
       ))}
        </Row>
        <GenerateDoc me={me} quizObject={quizObject} quizQuestion={quizQuestion} ResultData={newUserResultJSONObject}/>
       </>
        ) : (<>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </>
      )}
    </div>
  );
};

export default ResultsRenderB1;