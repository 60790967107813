import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Form, Row, Card, CardDeck, Col} from 'react-bootstrap';
import Confetti from 'react-confetti';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useQuizContext } from '../../utils/GlobalState';
import { ADD_RESULT } from '../../utils/mutations';
import { UPDATE_COMPLETED } from '../../utils/mutations';

//userResultTitle not hardcoded
//testing path
const path = require('path');
const relativePath = path.relative('/Users/anitaganti/Downloads/Quiz-Creation-Anita-Saturday-022721/client/src/components/QuestionsRender/QuestionsRenderA.js', '/Users/anitaganti/Downloads/Quiz-Creation-Anita-Saturday-022721/client/src/utils/GlobalState.js');
console.log({relativePath});

const RadioStyle = {
  width: "100px"
};

const LabelStyle = {
  fontSize: "0.7rem"
};

const QuestionsRenderB1 = (props) => {
  const [buttonSubmit, setButtonSubmit] = useState(false);
    const [addResult, { error }] = useMutation(ADD_RESULT);
    const [updateCompleted, { error1 }] = useMutation(UPDATE_COMPLETED);
    const {
        questions,
        quizNumber,
        quizId,
        username
      } = props;
    const [state] = useQuizContext(); 
    let history = useHistory(); 

const dbName = state.userdata;


/******** hardcoding defaultValues */
const defaultValues = {
   userResultTitle: ['defaultTitle'], userResultWeight: ['defaultOverview'], quizId: quizId
};

  const { register, handleSubmit, errors } = useForm(defaultValues);
  const onSubmit = async data => {
    let urt = [];
    let urw = []
    for (let jVal of Object.keys(data)) {
      var iVal = data[jVal];
      let Choice = questions[jVal].questionResponseChoices[iVal];
      let Weight = questions[jVal].questionResponseWeights[iVal];
      let Question = questions[jVal].questionNumber;
      urt.push({ Question, Choice });
      urw.push({ Question, Weight });
      console.log("jVal", jVal, "iVal", iVal);
  };

  console.log("urt", urt, "urw", urw);

    console.log("data", data);
    let title = JSON.stringify(urt);
    let weight = JSON.stringify(urw); 
    const dataJSON = { userResultTitle:[ title ], userResultWeight: [ weight], quizId: quizId };
    let completed = parseInt(quizNumber, 10);
    console.log(dataJSON);
    console.log({quizNumber});
    console.log({completed});
    try {
        // execute addResultTitle to the  mutation and pass in variable data from formState
        //upon success, destructure data object from response of mutation and console log
        const result  = await addResult({
          variables: { ...dataJSON }
        });
      } catch (e) {
        console.error(e);
      };

    try {
        // update complete to User
        const complete  = await updateCompleted({
          variables:{ completed } 
        });
      } catch (e) {
        console.error(e);
      };
      setTimeout(function(){ window.location.assign(`/quiz/${quizNumber}/result/:${username}?`) }, 3000);
      setButtonSubmit(true);
  }; 


  return (
    <div>
        <Row className="justify-content-md-center justify-content-sm-center justify-content-xs-center">
         <Form onSubmit={handleSubmit(onSubmit)} >
        {questions &&  questions.map((question, j) => (
              <Col key={questions[j]._id}>
              <CardDeck>
                    <Card className="text-left questioncard" >
                      <Card.Body>
                        <Card.Title>
                        {questions[j].questionTitle}
                        </Card.Title>
                          {questions[j].questionResponseChoices.map((choice, i) => (
                          <Row key={questions[j].questionResponseChoices[i]}>
                          <label className="text-center radio has-background-light" style={LabelStyle} >
                          <Form.Check inline type="radio" name={j} value={i} style={RadioStyle} ref={register({ required: true })}/>
                          {choice}
                          </label>
                         </Row> 
                          ))}
                      </Card.Body>
                      </Card>
              </CardDeck>
              <br />
          </Col>
          ))}
          <Button 
              className='btn d-block w-75 mx-auto' 
              type='submit'
              variant='success'
              disabled = {buttonSubmit}
          >{buttonSubmit ? 'Submitted!! ✈ ' : 'Submit'}
          </Button>
          {buttonSubmit ? 
              <Confetti
               width={window.innerWidth}
               height={window.innerHeight}
                />
            : ''}
          <a href={`/quiz/${quizNumber}/result/:${username}?`} className="text-custom my-1 floating-right px-3 mx-4">Click for result</a>
          </Form>
          </Row>
      </div>
    )
}
export default QuestionsRenderB1