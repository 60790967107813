import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, CardDeck} from 'react-bootstrap';
import Auth from '../utils/auth';

const loggedIn = Auth.loggedIn();

const SearchItem = ({ search }) => {
  const { questionRef, questionTitle } = search;
  const quizNum = questionRef.split('.')[0];
  const questionNum = questionRef.split('.')[1];
  console.log(quizNum)

  return (
    <div className="my-5">
      {loggedIn ? (
      <Row>
          <Col>
           <CardDeck>
            <Link to={`/quiz/${quizNum}/question/${questionNum}`}>
            <Card className="searchcard">
            <Card.Body>
              <Card.Title>
              {/* //peppercorn specific name change from Quizzes to Exercises */}
                Quiz: {quizNum} ᐳ
                Question: {questionNum} ᐳ 
              </Card.Title>
              <Card.Text>
              <div className="dynamic-content-div"> {questionTitle.slice(0,200)} </div>
              ...
            </Card.Text>
            </Card.Body>
            <Card.Footer>
               <small className="text-muted">Click to open this Result</small>
            </Card.Footer>
          </Card>
          </Link>
          <br />
          </CardDeck>
          </Col>
        </Row>
        ) : (<>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </>
        )}
    </div>
  );
};

export default SearchItem;