import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        _id
      }
    }
  }
`;
//example {"email": "adminita@hadeda.ai", "password": "password" }

export const ADD_USER = gql`
mutation addUser ($username: String!, $email: String!, $password: String!, $uniqueid: String!, $role: String) {
  addUser (username: $username, email:$email, password: $password, uniqueid: $uniqueid, role: $role){
    token
    user {
      _id
      username
      uniqueid
      password
      role
    }
  }
}
`;
//example {"username":"anita",  "email": "anita@hadeda.ai", "password": "password", "uniqueid":"777", "role":"ADMIN" }

export const UPDATE_USER = gql`
mutation($email: String, $username: String,$password: String, $uniqueid: String){
  updateUser(email: $email, username: $username, password: $password, uniqueid: $uniqueid){
    email
    username
    password
    uniqueid
  }
}
`;

export const UPDATE_COMPLETED = gql`
mutation updateCompleted($completed:[Int]){
  updateCompleted(completed: $completed){
    email
    username
    password
    uniqueid
    completed
  }
}
`;

export const ADD_RESULT = gql`
mutation addResult($userResultTitle: [String]!, $userResultWeight: [String]!, $quizId : ID!) {
  addResult(userResultTitle: $userResultTitle, userResultWeight: $userResultWeight, quizId: $quizId) {
    _id
    userResult{
      _id
      userResultTitle
      userResultWeight
      userID{
        _id
      }
      quizId{
        _id
      }
    }
  }
}
  `;
//example: {"userResultTitle": "['String']", "userResultWeight": "['String']", "quizId": "601a409f3fbb74c1a536a315"}

export const CONFIRM_USER = gql`
mutation confirmUser($confirmed: Boolean!, $idToken: String!) {
  confirmUser(confirmed: $confirmed, idToken:$idToken){
    token
    user{
      confirmed
    }
  }
}
`

export const FORGOT_PASSWORD = gql`
mutation forgotPassword($email: String!) {
  forgotPassword(email: $email){
    email
  }
}
`

export const RECOVER_PASSWORD = gql`
mutation recoverPassword($email: String!, $password: String!) {
  recoverPassword(email: $email, password: $password){
    username
  }
}
`

