import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Form, Row, Card, CardDeck, Col} from 'react-bootstrap';
import Confetti from 'react-confetti';
// import { useForm } from 'react-hook-form';
import { useForm, useField, splitFormProps } from "react-form";
import MultiSelectField from './MultiSelectField';
import { useHistory } from 'react-router-dom';
import { useQuizContext } from '../../utils/GlobalState';
import { ADD_RESULT } from '../../utils/mutations';
import { UPDATE_COMPLETED } from '../../utils/mutations';

//userResultTitle not hardcoded
//testing path
const path = require('path');
const relativePath = path.relative('/Users/anitaganti/Downloads/Quiz-Creation-Anita-Saturday-022721/client/src/components/QuestionsRender/QuestionsRenderA.js', '/Users/anitaganti/Downloads/Quiz-Creation-Anita-Saturday-022721/client/src/utils/GlobalState.js');
console.log({relativePath});

const createOptionChoices = (choices) => {
  let choiceslength = choices.length
  let createdoption = [];
for (let i = 0; i < choiceslength; i += 1) { 
  let valueVal = i;
  let labelVal = choices[i];
  createdoption.push({ value: valueVal, label: labelVal });
 };
//  console.log({createdoption})
 return (createdoption)
}

const QuestionsRenderG = (props) => {
  const [buttonSubmit, setButtonSubmit] = useState(false);
    const [addResult, { error }] = useMutation(ADD_RESULT);
    const [updateCompleted, { error1 }] = useMutation(UPDATE_COMPLETED);
    const {
        questions,
        quizNumber,
        quizId,
        username
      } = props;
    const [state] = useQuizContext(); 
    let history = useHistory(); 

// const dbName = state.userdata;
// /******** hardcoding defaultValues */
// const defaultValues = {
//    userResultTitle: ['defaultTitle'], userResultWeight: ['defaultOverview'], quizId: quizId
// };

  const {
    Form,
    meta: { canSubmit }
  } = useForm({
    debugForm: false,
    onSubmit: async values => {
      console.log("Huzzah!", values, canSubmit);
      let urt = [];
      let urw = []
      let valueslength = values.length; //1
      for (let i = 0; i < valueslength; i += 1) { 
       let jVal = i;
       var iVal = values[jVal]; //["0", "1"]
       let ArrayChoice =[]
       let Weight = 0;
       for (let k = 0; k < iVal.length; k += 1) { 
        let kVal = iVal[k]
        ArrayChoice.push( questions[jVal].questionResponseChoices[kVal] );
        //console.log({ArrayChoice})
        Weight = Weight + questions[jVal].questionResponseWeights[kVal]
       }
       //console.log("out of loop", {ArrayChoice})
       let Choice = ArrayChoice.join(',');
       let Question = questions[jVal].questionNumber;
       urt.push({ Question, Choice });
       urw.push({ Question, Weight });
       console.log("jVal", jVal, "iVal", iVal);
      };
   
      console.log("urt", urt, "urw", urw);
      console.log("values", values);
      let title = JSON.stringify(urt);
      let weight = JSON.stringify(urw); 
      const valuesJSON = { userResultTitle:[ title ], userResultWeight: [ weight], quizId: quizId };
      let completed = parseInt(quizNumber, 10);
      console.log(valuesJSON);
      console.log({quizNumber});
      console.log({completed});
      try {
        // execute addResultTitle to the  mutation and pass in variable data from formState
        //upon success, destructure data object from response of mutation and console log
        const result  = await addResult({
          variables: { ...valuesJSON }
        });
      } catch (e) {
        console.error(e);
      };

    try {
        // update complete to User
        const complete  = await updateCompleted({
          variables:{ completed } 
        });
      } catch (e) {
        console.error(e);
      };
      setTimeout(function(){ window.location.assign(`/quiz/${quizNumber}/result/:${username}?`) }, 3000);
      setButtonSubmit(true);
    
    }
  });

  return (
    <div>
<Row className="justify-content-md-center justify-content-sm-center justify-content-xs-center">
    <Form>
      {questions &&  questions.map((question, j) => (
         <Col key={questions[j]._id}>
              <CardDeck>
               <Card className="text-left questioncard" >
                  <Card.Body>
                   <Card.Title>
                   {questions[j].questionTitle}
                   </Card.Title>
                    <div>
                    <label>
                      {""}
                      <MultiSelectField
                        field = {`${j}`}
                        options={createOptionChoices(question.questionResponseChoices)}
                        validate={value =>
                          (value.length < (parseInt(question.questionTime)||1) ? `At least ${question.questionTime} choices are required!` : false)}
                      />
                   </label>
                   </div>
                  </Card.Body>
               </Card>
             </CardDeck>
              <br />
          </Col>
          ))}

      <div>
        <Button 
          className='btn d-block w-75 mx-auto' 
          type='submit'
          variant='success'
          disabled = {buttonSubmit && !canSubmit}
        >
          {buttonSubmit ? 'Submitted!! ✈ ' : 'Submit'}
        </Button>
        {buttonSubmit ? 
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
          />
        : ''}
        <a href={`/quiz/${quizNumber}/result/:${username}?`} className="text-custom my-1 floating-right px-3 mx-4">Click for result</a>
      </div>
    </Form>
   </Row>
      </div>
    )
}
export default QuestionsRenderG