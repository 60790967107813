import React, { useState } from 'react';
// redirect to another route, retrieves username from the URL
import { Redirect, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import CompletedQuizzes from '../components/CompletedQuizzes';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER, QUERY_ME } from '../utils/queries';
import Auth from '../utils/auth';

//import FriendList from '../components/FriendList';
/* 45 -53
 const handleClick = async () => {
    try {
      await addFriend({
        variables: { id: user._id }
      });
    } catch (e) {
      console.error(e);
    }
  };
          {userParam && (
          <button className="btn ml-auto" onClick={handleClick}>
            Add Friend
          </button>
        )}
  */
/* 64 - 70
        <div className="col-12 col-lg-3 mb-3">
          <FriendList
            username={user.username}
            friendCount={user.friendCount}
            friends={user.friends}
          />
        </div>
   line 74
         <div className="mb-3">{!userParam && <ThoughtForm />}</div>     
*/

//import { ADD_FRIEND } from '../utils/mutations'; 


const Profile = () => {
  const { username: userParam } = useParams();
  const [formState, setFormState] = useState({ amount: ''});
  // useParams Hook retrieves the username from the URL which is then passed to the useQuery Hook
  // if no userParam from the URL bar, use value to run QUERY_ME( ex. visit /profile while logged in)
  const { loading, data } = useQuery(userParam ? GET_USER : QUERY_ME, {
    variables: { username: userParam }
  });



  // when QUERY_ME, return data in the me property, QUERY_USER, return data.user
  const user = data?.me || data?.user || {};
  //***const [addFriend] = useMutation(ADD_FRIEND);
// ************need to add code here to extract completed quizId from userResult
  // redirect to personal profile page if username is the logged-in user's
  // check to see if username stored in the JSON Web Token is the same as the userParam 
  if (Auth.loggedIn() && Auth.getProfile().data.username === userParam) {
    return <Redirect to="/profile" />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="text-center">
        <h2 className="bg-light text-primary p-3">
          {userParam ? `${user.username}'s` : 'Your'}  profile page
        </h2>
        <hr />
        
      </div>

      <div className="flex-row justify-space-between my-2">
      {/* //peppercorn specific name change from Quizzes to Exercises */}
      <h3 className="my-2 mx-2">Here are {user.username}'s completed Quizzes</h3>
        <Row>
        <Col sm={8}>
          <CompletedQuizzes completedQuizzes={user.completed}/>
        </Col>
        </Row>
  </div>
    </div>
  );
};

export default Profile;
