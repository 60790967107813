// React packages and UI
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {  Card, Badge, Row, Col, CardDeck } from 'react-bootstrap';
// Global State
import { useQuizContext } from '../utils/GlobalState';
import { idbPromise } from '../utils/helpers';
import { USER_DATA, UPDATE_QUIZZES } from '../utils/actions';
// Components
import CloudSpinner from '../components/CloudSpinner';
import AllResultsComponent from './AllResultsComponent';
import Auth from '../utils/auth';
// GraphQL API
import { GET_ALL_QUIZZES, QUERY_ME } from '../utils/queries';
import { useQuery } from '@apollo/react-hooks';

const JournalCard = {
  marginBottom: "10px"
};

const AllResults = () => {
  //map through all quizzes. check for quiz category. 
  //based on quiz category point them to the appropriate results render
  //if the user has taken the quiz the latest result is rendered.
  //else a message that says the quiz isn't taken
  //const { quizNum } = useParams(); //may not be needed for all results
    const [state, dispatch] = useQuizContext();
 
    const { loading: userLoading, data: userData } = useQuery(QUERY_ME);
    const { loading: quizLoading, data: quizData } = useQuery(GET_ALL_QUIZZES);

    const me = userData?.me || [];
    const answers = {...me.userResult};

    // console.log({answers});

    const handleRender = (StringCompare) => {
      let targetValue = 9272022;
      const parsed = parseInt(StringCompare, 10);
      // console.log(StringCompare, parsed);
      let falsyValue = (parsed !== targetValue);
      // console.log({falsyValue});
      return falsyValue
      }
      const handleJournal = (CategoryCompare) => { //if quizCategory is C1 then the quiz result is not rendered
        let targetValue = "CategoryC1";
        // const parsed = parseInt(StringCompare, 10);
        // console.log(StringCompare, parsed);
        let falsyValue = (CategoryCompare !== targetValue);
        // console.log({falsyValue});
        return falsyValue
        }

    useEffect(() => {
      if (quizData) {
        console.log({quizData})
        dispatch({
          type: UPDATE_QUIZZES,
          quizzes: quizData.quizzes
        });
        quizData.quizzes.forEach(quiz => {
          idbPromise('quizzes', 'put', quiz);
        });
      } else if (!quizLoading) {
        idbPromise('quizzes','get').then(quizzes => {
          dispatch({
            type: UPDATE_QUIZZES,
            quizzes: quizzes
          });
        });
      };
    
        if (userData) {
          dispatch({
            type: USER_DATA,
            userdata: userData.me
          });
        idbPromise('userdata','put', userData.me);
        } else if (!userLoading) {
          idbPromise('userdata','get').then(userdata => {
            dispatch({
              type: USER_DATA,
              userdata: userdata
            });
          });
        }
        console.log('update')
      }, [userData, userLoading, quizData, quizLoading, dispatch ]);

    const loggedIn = Auth.loggedIn();
    return (
      <div>
      {loggedIn ? (
      <div>
      <main>
        <div>
          {quizLoading && loggedIn ? <CloudSpinner /> : 
          <div>
          <Row>
          {quizData.quizzes &&
            quizData.quizzes.map((quiz) => (
            <Col key={quiz.quizNumber} md={{ span: 12, 
           //  offset: 1
            }}>
               { handleRender(quiz.quizPoster)? (<> 
                { handleJournal(quiz.quizCategory)? (<>
                <Link to={`/quiz/${quiz.quizNumber}/result/:${me.username}?`}>
                <CardDeck style={JournalCard}>
                   <Card className="searchcard"> 
                       <Card.Body>
                         <Card.Title>
                          {quiz.quizTitle} ({quiz.quizPoster}) 
                         </Card.Title>                      
                       <AllResultsComponent
                          quizNum={quiz.quizNumber}
                          username={me.username}
                        />
                       </Card.Body>
                   </Card>
               </CardDeck>
               </Link>
                </>):(<> </>)}
               </>):(<> </>)}
               </Col>             
             ))}
           </Row>
          </div>
          }
        </div>
      </main>
      </div>
           ) : (                
        <>
            {localStorage.setItem('referring_url', window.location.href)}
            <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page.</h4> 
        </>
              )}
      </div>
    );    
};
export default AllResults;
