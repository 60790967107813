import React from "react";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import expressions from 'angular-expressions';
import assign from "lodash/assign";
import last from "lodash/last";
import { saveAs } from "file-saver";
import { Button } from 'react-bootstrap';
import ResultDatatemplate from './ResultDatatemplate.docx';

function loadFile(url, callback) {
PizZipUtils.getBinaryContent(url, callback);
}

const GenerateDoc = (props) => {

const { me, quizObject, quizQuestion, ResultData} = props;
if(ResultData == null){
return null;
}

//Format to Render the Result
//extract most recent result for download
let resultLengthIndex = ResultData.length -1;
let renderResultData = ResultData[resultLengthIndex]
//end
let length = quizQuestion.length;
let questions = [{Question : " ", Answer : " "}];
for (let i = 0; i < length; i += 1) {
  const a = quizQuestion[i].questionTitle;
  const b = renderResultData[i].Choice;
  questions.push({ Question : `${a}`, Answer : `${b}` });
};
//end format


const generateDocument = () => {
loadFile(`${ResultDatatemplate}`, function(
error,
content
) {
if (error) {
throw error;
}
function angularParser(tag) {
if (tag === '.') {
return {
get: function(s){ return s;}
};
}
const expr = expressions.compile(
tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
);
return {
get: function(scope, context) {
let obj = {};
const index = last(context.scopePathItem);
const scopeList = context.scopeList;
const num = context.num;
for (let i = 0, len = num + 1; i < len; i++) {
obj = assign(obj, scopeList[i]);
}
obj = assign(obj, {"$index": index});
return expr(scope, obj);
}
};
}
const zip = new PizZip(content);
const doc = new Docxtemplater(zip, {parser:angularParser}, {linebreaks: true});
//https://docxtemplater.com/docs/tag-types/
//https://docxtemplater.com/demo/#loop-list


doc.setData({
Name: me.username,
Number: quizObject.quizNumber,
Title: quizObject.quizTitle,
questions: questions,
});

try {
// render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
doc.render();
} catch (error) {
// The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
function replaceErrors(key, value) {
if (value instanceof Error) {
return Object.getOwnPropertyNames(value).reduce(function(
error,
key
) {
error[key] = value[key];
return error;
},
{});
}
return value;
}
// console.log(JSON.stringify({ error: error }, replaceErrors));

if (error.properties && error.properties.errors instanceof Array) {
const errorMessages = error.properties.errors
.map(function(error) {
return error.properties.explanation;
})
.join("\n");
console.log("errorMessages", errorMessages);
// errorMessages is a humanly readable message looking like this :
// 'The tag beginning with "foobar" is unopened'
}
throw error;
}
const out = doc.getZip().generate({
type: "blob",
mimeType:
"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}); //Output the document using Data-URI
saveAs(out, `userdata_${quizObject.quizTitle}.docx`);
});
}

return (
<div className="p-2">
<Button 
    // className="btn-success btn-lg" 
    onClick={generateDocument}
    className='btn d-block w-100' 
    // type='submit'
    variant='custom'
    >
        Download Your Result
</Button>
</div>
);
};

export default GenerateDoc;