
// import pages
import Home from './pages/Home';
import Quizzes from './pages/Quizzes';
import Question from './pages/Question';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ConfirmEmail from './pages/ConfirmEmail'
import Result from './pages/Result';
import AllResults from './pages/AllResults'
import Journal from './pages/Journal'
import Profile from './pages/Profile';
import Terms from './pages/Terms';
import NoMatch from './pages/NoMatch';
import Search from './pages/Search';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';

//query all modules and render all modules in the home page
//on clicking a module go to route /:moduleNum - here are quizzes and journal associated with that module are rendered
//on clicking one quiz you go to /quiz/:quizNum
//first hard code module data so no query/actions/reducers are needed and render the new home page
//then create the quizzes render (by module) based on lessor render and test other routes
//copy the module schema, resolver, typedef, query, action and reducer over


// export each page as generated API 
//peppercorn specific name change from Quizzes to Exercises and results to answers
export default [
  { path: "/", name: "Exercises by Module ", Component: Home },
  { path: "/module/:moduleNum", name: `ᐳ Exercises for selected Module `, Component: Quizzes },
  { path: "/quiz/:quizNum", name: `ᐳ Selected Exercise `, Component: Question },
  // { path: "/quiz/:quizNum/result/:username?", name: "ᐳ Selected Result ", Component: Result },
  { path: "/quiz/:quizNum/result/:username?", name: "ᐳ Selected Answers ", Component: Result },
  { path: "/AllResults", name: "ᐳ AllResults ", Component: AllResults },
  { path: "/Journal", name: "ᐳ Journal ", Component: Journal },
  { path: "/login", name: "ᐳ Login ", Component: Login },
  { path: "/signup", name: "ᐳ Signup ", Component: Signup },
  { path: "/Terms", name: "ᐳ Terms ", Component: Terms },
  { path: "/profile/:username?", name: "ᐳ Profile ", Component: Profile },
  { path: "/search/:searchTerm",name: "ᐳ Search ", Component: Search},
  { path: "/login/forgot-password", name: "ᐳ Forgot Password", Component: ForgotPassword },
  { path: "/login/reset-password", name: "ᐳ Reset Password", Component: ResetPassword },
  { path: "/confirm/user/:idToken", name: "ᐳ Confirm Email", Component: ConfirmEmail },
  { name: "No match", Component: NoMatch },
];
