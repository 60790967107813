import React, { createContext, useContext } from "react";
import { useQuizReducer } from './reducers';

const QuizContext = createContext();
const { Provider } = QuizContext;

const QuizProvider = ({ value = [], ...props }) => {
    const [state, dispatch] = useQuizReducer({
      modules: [], // array of all modules
      quizzes: [], // array of all quizzes
      questions: [], // array of all questions inside of a quiz
      userdata: [], // object with user information (me)
      search: '',
    });
    // console.log({state}); // comment this in to test!
    return <Provider value={[state, dispatch]} {...props} />;
  };

const useQuizContext = () => {
    return useContext(QuizContext);
};

export { QuizProvider, useQuizContext };