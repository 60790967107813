import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Alert, Badge ,Card, Row, Col, CardDeck, Button } from 'react-bootstrap';
import Auth from '../../utils/auth';
import { useQuizContext } from '../../utils/GlobalState';
import { PersonalityRender, PersonalityQuiz } from '../Personalities/PersonalityRender';

const ResultCard = {
  marginBottom: "10px"
};

const ResultsRenderB = (props) => {
  const {
    me,
    answers,
    quizIdProp,
    quizObject,
    quizQuestion, 
  } = props;
//**** answers holds userResult - an object array of all results for quizzes taken by user thus is me.userResult
//**** quiz hold the _id of the current quiz taken by user from where they are passed to this page thus is quiz._id
//**** quizQuestion is an object array if questions based on the Question model.
const username= me.username;

const CardStyle ={
  backgroundColor: "transparent",
  borderColor: "transparent",
  padding: "0rem 0rem",
  borderRadius: "50%",
  border: "0px",
  boxSizing: "border-box",
  fontSize: "15px" ,
  fontWeight: "100",
  marginLeft: "16px",
  textAlign: "left"
};

const LinkStyle ={
  backgroundColor: "#F2A90B",
  borderColor: "#F2A90B",
  color: "black",
  padding: "0.2rem 0.2rem",
  borderRadius: "2%",
  border: "1px",
  boxSizing: "border-box",
  fontSize: "15px " ,
  fontWeight: "100",
  marginLeft: "16px",
  textAlign: "left"
};

const LinkStyle2 ={
  backgroundColor: "#303551",
  borderColor: "#303551",
  color: "#FFFFFF",
  padding: "0.2rem 0.2rem",
  borderRadius: "2%",
  border: "1px",
  boxSizing: "border-box",
  fontSize: "15px " ,
  fontWeight: "100",
  marginLeft: "16px",
  textAlign: "left"
};
const [state] = useQuizContext();


// const [show, setShow] = useState(true);


// Lookup if the userResult Object array exists for this quiz
//const answerLookup is a Boolean, and true if the quiz result exists
  const answerLookup = answers.map((element) => element.quizId[0]).map(e => e._id).includes(`${quizIdProp}`);
  
  // Create array with results to render for quiz. 
  let results = [];
  if (answerLookup) {
    answers.map((answer) => {
      answer.quizId.map((id) => {
        if(id._id !== `${quizIdProp}`) {
          return false;
        }
        results.push(answer)
        return console.log({results});
      })
      return console.log({results});
    })
  }

//parsing the results array to get a JSON Object
  let userResultTitleJSONObject =[];
  if (answerLookup) {
    results.map((answer) => {
      answer.userResultTitle.map((urt) => {
        const urtJson = JSON.parse(urt)
        console.log("urtJson", urtJson)
        userResultTitleJSONObject.push(urtJson)
        return console.log({userResultTitleJSONObject});
        })
      return console.log({userResultTitleJSONObject});
      });
    };
  console.log("userResultTitleJSONObject",userResultTitleJSONObject);

  let userResultWeightJSONObject =[];
  if (answerLookup) {
    results.map((answer) => {
      answer.userResultWeight.map((urw) => {
        const urtJsonW = JSON.parse(urw);
        console.log("urtJsonW", urtJsonW);
        userResultWeightJSONObject.push(urtJsonW);
        return console.log({userResultWeightJSONObject});
        })
      return console.log({userResultWeightJSONObject});
      });
    };
  console.log("userResultWeightJSONObject",userResultWeightJSONObject);

// combining  both these parsed JSON Object arrays into 1 New Array while removing duplicates
 const mergeByProperty = (target, source, prop) => {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement[prop] === targetElement[prop];
      })
      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
      target._id = `${(Math.floor(Math.random() * 100))}`;
      //urtJsonW._id = "smith"; way to add id value to array to map with React unique key;
    })
  };
   
const bin_to_dec = (bstr) => { 
    return parseInt((bstr + '')
    .replace(/[^01]/gi, ''), 2);
  };

 const personalityQuizFunction = (score) => {
  let eiFactor = (100*(Math.floor(score/100)));
  let eiScore = score - eiFactor;
  let snFactor = (10000*(Math.floor(eiFactor/10000)));
  let snScore = eiFactor - snFactor;
  let ftFactor = (1000000*(Math.floor(snFactor/1000000)));
  let ftScore = snFactor - ftFactor;
  let pjScore = 1*ftFactor;
  let [eiIndex, snIndex, ftIndex, pjIndex] = [0, 0, 0, 0];
  eiScore < 6 ? eiIndex = 1 : eiIndex = 0;
  snScore < 2100 ? snIndex = 0 : snIndex = 10;
  ftScore < 210000 ? ftIndex = 0 : ftIndex = 100;
  pjScore < 16000000 ? pjIndex = 0 : pjIndex = 1000;
  let indexArray = [eiIndex, snIndex, ftIndex, pjIndex];
  let indexCount = indexArray.reduce(function(a, b) { return a + b; }, 0);// binary value of personality score
  let pqIndex = bin_to_dec(`${indexCount}`);//decimal value of personality score to be used to identify personality type
  console.log({eiFactor}, {snFactor}, {ftFactor}, {pjScore}, {eiScore}, {snScore}, {ftScore}, {pjScore}, {indexArray}, {indexCount}, {pqIndex});
  return pqIndex;
  };

//parse/stringify, was the best way to copy without overwriting original array
let length = userResultTitleJSONObject.length;
let newUserResultJSONObject=[];
for (let i = 0; i < length; i += 1) {
  const target = JSON.parse(JSON.stringify(userResultTitleJSONObject[i]));
  const source = JSON.parse(JSON.stringify(userResultWeightJSONObject[i]));
  mergeByProperty(target, source, 'Question');
  console.log("target", target);
  //extract and array of the weights
  let scoreArray = target.map(a => a.Weight);
  console.log("scoreArray", scoreArray);
  //total all the integers in the weights array
  let attemptScore = scoreArray.reduce(function(a, b) { return a + b; }, 0);
  console.log("attemptScore", attemptScore);
  //Create and add attempScore key/value to object target
  target.attemptScore = attemptScore;
  //The next two lines of code apply to a peronality type quiz only
  let quizOverviewArrayIndex = personalityQuizFunction(attemptScore);
  target.quizOverviewArrayIndex = (quizOverviewArrayIndex + 1); // quizOverviewArrayIndex key has the value of the index value to extract from quizOverview 
  //****/
  newUserResultJSONObject.push(target);
};
console.log("newUserResultJSONObject", newUserResultJSONObject)
//console.log("personality calculated", quizObject.quizOverview[newUserResultJSONObject[0].quizOverviewArrayIndex])
//example newUserResultJSONObject : Array(2)
//0: {Question: 51, Choice: "Washington DC", Weight: 1}
//1: {Question: 52, Choice: "Sacramento", Weight: 1}
//attemptScore: 2
//quizOverviewArrayIndex:14
//_id: "71"

//if no data return no results
  if (!answers || !quizQuestion || !quizIdProp || !answerLookup) {
//peppercorn specific name change from Quizzes to Exercises and results to answers
    return <h1>There are no answers for this Exercise!</h1>;
  };

//https://discovery-quiz-062022.herokuapp.com/quiz/40/result/:user1?

  const loggedIn = Auth.loggedIn();
  return (
    <div>
      {loggedIn && answerLookup ? (
       <>
        <div className="text-right">
          {/* {!show && <Button  onClick={() => setShow(true)} variant="info">Show Answers</Button>}
          {show && <Button onClick={() => setShow(false)} variant="outline-info">Close Answers</Button>} */}
        </div>
        <Row className="justify-content-md-center justify-content-sm-center justify-content-xs-center">
        {newUserResultJSONObject.map((newUserResultJSONObject1, i) => (
        <Col key={newUserResultJSONObject1._id} md={{ span: 12, 
          //  offset: 1
        }}>
        <CardDeck style={ResultCard}>
         <Card className="searchcard"> 
         <Badge className="pill-center mx-2 my-2" variant="custom">Attempt {i+1}</Badge>
          {/* <Badge className="pill-center mx-2 my-2" variant="custom">Score {newUserResultJSONObject1.attemptScore}</Badge> */}
          {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
              <Card.Title style={CardStyle} className="text-left my-2">
                <p> Your responses for this reflection indicates that you may have some of the preferences listed below for {quizObject.quizOverview[newUserResultJSONObject1.quizOverviewArrayIndex]}.</p>
                <p> But everyone is different and not all these may apply to you.</p>
                <p> The link below allows you to review and select the appropriate ones.</p>
                <a style={LinkStyle} href={`/quiz/${PersonalityQuiz(quizObject.quizOverview[newUserResultJSONObject1.quizOverviewArrayIndex])}`} className="text-custom my-1 floating-left px-2 mx-4">Next Step: Click here to choose preferences that apply </a>
{/* The PersonalityQuiz function returns the right quiz number for the personality type calculated */}
                <hr size="8" width="100%" color="transparent"/>  
                <p> 
                Click below, if you have previously already selected your preferences.
                </p>
                  <a 
                    style={LinkStyle2} 
                    href={`/quiz/${PersonalityQuiz(quizObject.quizOverview[newUserResultJSONObject1.quizOverviewArrayIndex])}/result/:${username}?`} 
                    className="text-custom my-1 floating-left px-2 mx-4">
                    Review preferences you selected
                  </a>

                
              </Card.Title>            
              <Row>
              <Col>
              <Card.Body>
                <div>
                {/* <Row>
                    <Col>
                      Your Personality Type: {quizObject.quizOverview[newUserResultJSONObject1.quizOverviewArrayIndex]}
                    </Col>
                </Row> */}
                {/* <Alert show={show} variant={newUserResultJSONObject1.Weight !== 0 ? 'success' : 'danger'}> */}
                              {PersonalityRender(quizObject.quizOverview[newUserResultJSONObject1.quizOverviewArrayIndex])}
{/* The PersonalityRender function returns the right HTML for the personality type calculated */}
                {/* </Alert> */}
                </div>
              </Card.Body> 
              </Col>
              </Row>   
              </Card>
             </CardDeck>
         </Col>
       ))}
        </Row>
       </>
        ) : (
          <>
          {localStorage.setItem('referring_url', window.location.href)}
         <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
         </>
      )}
    </div>
  );
};

export default ResultsRenderB;