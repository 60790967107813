import React from "react";
import { useForm, useField, splitFormProps } from "react-form";


const MultiSelectField = (props) => {

  const [field, fieldOptions, { options, ...rest }] = splitFormProps(props);

  const {
    value = [],
    setValue,
    meta: { isTouched, error }
  } = useField(field, fieldOptions);

  const handleSelectChange = e => {
    const selected = Array.from(e.target.options)
      .filter(option => option.selected)
      .map(option => option.value);

    setValue(selected);
  };

  return (
    <>
      <select {...rest} value={value} onChange={handleSelectChange} multiple>
        <option disabled value="" />
        {options.map(option => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {isTouched && error ? <em>{error}</em> : null}
    </>
  );
}

export default MultiSelectField;