import React from 'react';
//receive a title and the thoughts array. destructured to avoid using props.title, props.thoughts
// lines 15 -24 /*
/*<p className="card-header">
<Link
  to={`/profile/${quiz.username}`}
  style={{ fontWeight: 700 }}
  className="text-light"
>
  {quiz.username}
</Link>{' '}

</p>
*/
import { Link } from 'react-router-dom';
const CompletedQuizzes = ({ completedQuizzes}) => {
  if (!completedQuizzes.length) {
    return <h3 className="mx-2">No completed quizzes Yet</h3>;
  }

  return (
    <div>
      {completedQuizzes &&
        completedQuizzes.map(quiz => (
          <div key={quiz} className="card mb-3 mx-2">

            <div className="card-body">
              <Link to={`/quiz/${quiz}`}>
                <div>{quiz} : {quiz}</div>
              </Link>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CompletedQuizzes;