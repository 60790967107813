import gql from 'graphql-tag';

export const QUERY_ME = gql`
query{
  me{
    _id
    username
    email
    role
    uniqueid
    completed
    confirmed
    friends{
      _id
      username
      email
      userResult{
        _id
      }
    }
    userResult{
      _id
      resultReleaseDate
      userResultTitle
      userResultWeight
      userID{
        _id
      }
      quizId{
        _id
      }
    }
  }
}
`;
// HTTP Headers example {"Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJuYW1lIjoiYWRtaW5pdGEiLCJlbWFpbCI6ImFkbWluaXRhQGhhZGVkYS5haSIsIl9pZCI6IjYwMWIzODJmZDg0MjgwZTAzYWMwYmExMCIsInJvbGUiOiJBRE1JTiJ9LCJpYXQiOjE2MTIzOTY5MTEsImV4cCI6MTYxMjQxODUxMX0.HLSjeNPjS56RH59r0zAWmi9-Tkg1b9GvecTxFkpYhlw"}

export const GET_USER = gql`
query user($username: String!)
{
  user(username:$username){
    _id
    username
    email
    role
    completed
    userResult{
      _id
    }
    friends{
      _id
      username
    }
  }
}
`;


export const GET_ALL_USERS = gql`
query users {
  users {
    _id
    username
    password
    role
    uniqueid
    friends{
      _id
      username
      email
      role
    }
    userResult {
      _id
      userResultTitle
      userResultWeight
    }
  }
}
`;
//example need to provide Admin credentials example - {"Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJuYW1lIjoiYWRtaW5pdGEiLCJlbWFpbCI6ImFkbWluaXRhQGhhZGVkYS5haSIsIl9pZCI6IjYwMWIzODJmZDg0MjgwZTAzYWMwYmExMCIsInJvbGUiOiJBRE1JTiJ9LCJpYXQiOjE2MTIzOTY5MTEsImV4cCI6MTYxMjQxODUxMX0.HLSjeNPjS56RH59r0zAWmi9-Tkg1b9GvecTxFkpYhlw"}
export const GET_ALL_MODULES = gql`

query modules {
  modules {
    _id
    moduleNumber
    moduleTitle
    moduleOverview
    modulePoster
    moduleCategory
  }
}
`;

export const GET_ALL_QUIZZES = gql`
query quizzes {
  quizzes {
    _id
    quizNumber
    quizTitle
    quizOverview
    quizReleaseDate
    quizPoster
    quizCategory
    quizQuestion{
      _id
      questionNumber
      questionTitle
      questionType
      questionImage
      questionResponseChoices
      questionResponseWeights
      questionRef
      questionReleaseDate
      questionTime
    }
  }
}
`;

export const GET_SELECTED_QUIZ = gql` 
query quiz($quizNum: Int!){
  quiz(quizNumber: $quizNum){
    _id
    quizNumber
    quizTitle
    quizOverview
    quizReleaseDate
    quizPoster
    quizCategory
    quizQuestion{
      _id
      questionNumber
      questionTitle
      questionType
      questionImage
      questionResponseChoices
      questionResponseWeights
      questionRef
      questionReleaseDate
      questionTime
    }
  }
}
`;
//{"quizNum": 1}

export const GET_ALL_QUESTIONS = gql`
query questions{
  questions{
    _id
    questionNumber
    questionTitle
    questionType
    questionImage
    questionResponseChoices
    questionResponseWeights
    questionRef
    questionReleaseDate
    questionTime
  }
}
`;

export const GET_SELECTED_QUESTION = gql` 
query question($questionNum: Int!){
  question(questionNumber: $questionNum){
    _id
    questionNumber
    questionTitle
    questionType
    questionImage
    questionResponseChoices
    questionResponseWeights
    questionRef
    questionReleaseDate
    questionTime
  }
}
`;
//{"questionNum": 1}

export const GET_ALL_RESULTS = gql`
query results {
  results {
    _id
    userResultTitle
    userResultWeight
    userID{
      _id
    }
    quizId{
      _id
      quizNumber
    }
    
  }
}
  `;

export const GET_SELECTED_RESULT = gql` 
query result($_id: ID!){
  result(_id: $_id){
    _id
		userResultTitle
    userResultWeight
    userID{
      _id
      username
    }
    quizId{
      _id
      quizNumber
    }

  }
}
`;
//example { "_id": "601a409f3fbb74c1a536a319" }

export const SEARCH_QUERY = gql`
  query searchQuery($search: String!) {
    searchQuery(search: $search) {
      currentPage
      totalPages
      questions {
        questionTitle
        questionRef
      }
    }
  }
`;
//example {"search": "javascript"}