import React from 'react'
import ToolTipCustom from './ToolTipCustom';


const Footer = () => {
    return (
        <footer>
            <ToolTipCustom
                placement='top'
                tooltipContent='New feature wish?'
                overlayContent={<a href="mailto:info@peppercorn.ai" className = 'footer-link'> Peppercorn Discovery </a>}
                tooltipVariant='custom'
                badgeVariant='clear'
            />
            <ToolTipCustom
                placement='top'
                tooltipContent='All Rights Reserved.'
                overlayContent='© Peppercorn Discovery'
                tooltipVariant='custom'
                badgeVariant='clear'
            />
</footer>
    )
};
export default Footer;