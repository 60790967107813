// see SignupForm.js for comments
import React, { useState, useEffect } from 'react';
import Auth from '../utils/auth';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, Alert, Col } from 'react-bootstrap';
import { LOGIN } from '../utils/mutations';
import { useMutation } from '@apollo/react-hooks';
import queryString from 'query-string';


const Login = () => {
    const [login, {error} ] = useMutation(LOGIN);
    const [formState, setFormState] = useState({ email: '', password: '' });
    const [validated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const parsed = queryString.parse(window.location.search)
    
    useEffect(() => {
        if(parsed.token != null) {
            try {
                const verified = Auth.verifyToken(parsed.token);
                Auth.login(parsed.token);
            } catch(e){
                console.error(e);
            }
        }
    },[parsed])
   
   
    let history = useHistory();
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value
        });
    };

    const handleFormSubmit = async event => {
        event.preventDefault();

        // check if form has everything (as per react-bootstrap docs)
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        try {
            const { data } = await login({
                variables: {...formState}
            });
            Auth.login(data.login.token);
        } catch (e) {
            console.error(e);
            setShowAlert(true);
        }

        setFormState({
            username: '',
            email: '',
            password: '',
        });
    };

    return (
        <>
            <Col className="logincard" md={{ span: 6, offset: 3}}>
            {/* I set forgot password link to main application from quiz and removed signup option from quiz app <Link className="btn btn-link" to="/signup">← Go to Sign Up</Link> */}
            <h3 className=" my-2 text-center">Login</h3>
            <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant='danger'>
                 The provided credentials do not match!
                </Alert>
                <Form.Group>
                    <Form.Label htmlFor='email'>Email</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='email'
                        name='email'
                        onChange={handleInputChange}
                        value={formState.email}
                        required
                    />
                    <Form.Text className="text-muted">
                      We'll use this to authenticate against your Course credentials.
                    </Form.Text>
                    <Form.Control.Feedback type='invalid'>Email is required!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label htmlFor='password'>Password</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Your password'
                        name='password'
                        onChange={handleInputChange}
                        value={formState.password}
                        required
                    />
                    <Form.Control.Feedback type='invalid'>Password is required!</Form.Control.Feedback>
                </Form.Group>
                <Button
                    disabled={!(formState.email && formState.password)}
                    type='submit'
                    variant='success'
                    >
                    Login
                </Button>
                {error && <div>Login failed</div>}
            </Form>
            <div className="form-group col text-right">
                <a className="btn btn-link pr-0" href="https://discovery-journey-062022.herokuapp.com/login/forgot-password">
                Forgot Password →
                </a>
                {/* I set forgot password link to main application from quiz and removed signup option from quiz app
                <Link to={`/login/forgot-password`} className="btn btn-link pr-0">Forgot Password →</Link> */}
            </div>
            </Col>
        </>
    );
};

export default Login;