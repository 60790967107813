import React from 'react';
import { Link } from "react-router-dom";
import Auth from '../utils/auth';
import SearchItem from './SearchItem';

const SearchRender = (props) => {
  const {
    searchTerm,
    search
  } = props;
 
  if (!search.length) {
    return <h4>There is no data for this search term.</h4>;
  }

  const loggedIn = Auth.loggedIn();

  return (
    <div className="container my-2">
      <hr />
      {loggedIn ? (
       <div>
      {search.map((search, index) => (
            <SearchItem key={index} search={search} />
          ))}
       </div>
     ): ( <>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page</h4>
          </>
        )}
    </div>   
   );
  };

export default SearchRender;