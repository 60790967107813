// React packages and UI
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
// Global State
import { useQuizContext } from '../utils/GlobalState';
import { idbPromise } from '../utils/helpers';
import { USER_DATA, UPDATE_QUESTIONS } from '../utils/actions';
// Components
import AllResultsRenderC1 from '../components/JournalRender/AllResultsRenderC1';
import Auth from '../utils/auth';
// GraphQL API
import { GET_SELECTED_QUIZ, QUERY_ME } from '../utils/queries';
import { useQuery } from '@apollo/react-hooks';



const JournalComponent = (props) => {
    //const { quizNum } = useParams();
    const [state, dispatch] = useQuizContext();
    const {
      quizNum,
      username
    } = props;
 
    const { loading: userLoading, data: userData } = useQuery(QUERY_ME);
    const { loading: questionLoading, data: questionData } = useQuery(GET_SELECTED_QUIZ, {
        variables: { quizNum: Number(quizNum) }
      });

    const quiz = questionData?.quiz || [];
    const me = userData?.me || [];
    const answers = {...me.userResult};
    let quizId = quiz._id;
    // let date1 = new Date(parseInt(1663960275932));
    // let date2 = new Date(parseInt(answers[0].resultReleaseDate));
    // let formatdate1 = (date1.getMonth() + 1)+'/'+ date1.getDate()  +'/'+date1.getFullYear()
    // let formatdate2 = (date2.getMonth() + 1)+'/'+ date2.getDate()  +'/'+date2.getFullYear()
    // console.log("what is in answers", {answers}, {date2}, {formatdate1}, {formatdate2});

    useEffect(() => {
        if (questionData) {
          dispatch({
            type: UPDATE_QUESTIONS,
            questions: quiz
          });
        quiz.quizQuestion.forEach(quiz => {
            idbPromise('questions', 'put', quiz);
          });
        } else if (!questionLoading) {
          idbPromise('questions','get').then(questions => {
            dispatch({
              type: UPDATE_QUESTIONS,
              questions: questions
            });
          });
        };
    
        if (userData) {
          dispatch({
            type: USER_DATA,
            userdata: userData.me
          });
        idbPromise('userdata','put', userData.me);
        } else if (!userLoading) {
          idbPromise('userdata','get').then(userdata => {
            dispatch({
              type: USER_DATA,
              userdata: userdata
            });
          });
        }
        // console.log('update')
      }, [userData, userLoading, questionData, questionLoading, dispatch ]);

    const loggedIn = Auth.loggedIn();

    switch(quiz.quizCategory) {
      case "CategoryC1":
        return (
          <div>
          {loggedIn ? (
         <main>
             <div>              
         {questionLoading ? (
             <Spinner className="spinner" animation="grow" />
         ) : (
             <>
             {/* //peppercorn specific name change from Quizzes to Exercises and results to answers */}
             {/* <h3 className="text-center">Your answers for {quiz.quizTitle}.</h3> */}
             </>
         )}
           </div>
         <div>
         {userData 
        //  && me.userResult.length
         ? (
         <>
             <AllResultsRenderC1
                answers={me.userResult} 
                quizIdProp={quiz._id}
                quizObject={quiz}
                quizQuestion = {quiz.quizQuestion}
                quizNum ={quizNum}
                username ={username}
             />
         </>
         //peppercorn specific name change from Quizzes to Exercises and results to answers
           ) : (<h4 className="text-center">No answers yet.</h4>)} 
         </div>
         </main>
             ) : (                <>
                    {localStorage.setItem('referring_url', window.location.href)}
                    <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access this page.</h4> 
                </>
         )}
           </div>
      )
      break;
      default:
        return (
          <div> 
            
          </div>
        )
     };
};
export default JournalComponent;
