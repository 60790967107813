import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
// import routing
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import routes from './routes';
// stylesheets
import './App.scss';
// import components
import { AppNavbar, Breadcrumbs }  from './components/index';
// import GlobalState
import { QuizProvider } from './utils/GlobalState';
//import scroll to top
import ScrollToTop from "./utils/ScrollToTop";
// Footer
import Footer from '../src/components/Footer';

const client = new ApolloClient({
  request: operation => {
      const token = localStorage.getItem('id_token');
      operation.setContext({
          headers: {
              authorization: token ? `Bearer ${token}` : ''
          }
      })
  },
  uri: '/graphql'
});

function App() {
  // Uncomment when deploying to production
  // console.log = console.warn = console.error = () => {};
  return (
    <ApolloProvider client={client}>
    <Router>
      <ScrollToTop>
      <div className="flex-row flex-column justify-space-around min-100-vh content">
        <QuizProvider>
        <AppNavbar />
          <div className="container">
          <Switch>
          {routes.map(({ path, name, Component }, key) => (
          <Route
            exact
            path={path}
            key={key}
            render={props => {
              const crumbs = routes
                // Get all routes that contain the current one.
                .filter(({ path }) => props.match.path.includes(path))
                // Swap out any dynamic routes with their param values.
                // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                .map(({ path, ...rest }) => ({
                  path: Object.keys(props.match.params).length
                    ? Object.keys(props.match.params).reduce(
                        (path, param) =>
                          path.replace(`:${param}`, props.match.params[param]),
                        path
                      )
                    : path,
                  ...rest
                }));
              // crumbs.map(({ name, path }) => console.log({ name, path }));
              return (
                <div className="p-2">
                  <Breadcrumbs crumbs={crumbs} />
                  <Component {...props} />
                </div>
              );
            }} />
        ))}
      </Switch>
        </div>
        </QuizProvider>
      </div>
      <Footer />
      </ScrollToTop>
    </Router>
    </ApolloProvider>
  );
}

export default App;
